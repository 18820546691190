import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../../../app/modules/auth'
import DashboardMain from './DashboardMain'
import {GetRegProgramme} from '../../modules/auth/core/_requests';

const DashboardWrapper = () => {
  const intl = useIntl()
  const {currentUser, logout} = useAuth()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Homepage</PageTitle>
      <DashboardMain/>
    </>
  )
}

export {DashboardWrapper}

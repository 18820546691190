import React,{FC,useState,useEffect} from "react";
import Carousel from 'react-bootstrap/Carousel';
import {toAbsoluteUrl} from '../../../_metronic/helpers'
type Props = {
	sliderdata: any[],
  }
const ImageSlider : React.FC<Props> = ({sliderdata})=>{
    return (
        <>
         <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <div className='col-xl-12'>
            <Carousel>
            {sliderdata.map((({title,imagexBase64}:any) => {
              return <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={imagexBase64}
                  alt={title}
                />
              </Carousel.Item>
              }))
              }
            </Carousel>
          </div>
        </div>       
        </>
    )
}
export default ImageSlider;
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth'

export function MenuInner() {
  const {currentUser} = useAuth()
  const intl = useIntl()

  return (
    <>
      <MenuItem title='Home' to={toAbsoluteUrl('/dashboard')} />
      {/* <MenuItem title='Be a Volunteer' to='/pages/wizards/BeAVolunteer' /> */}
      <MenuItem title='Volunteer' to={toAbsoluteUrl('/BeAVolunteer')} />
      <MenuInnerWithSub title='Programmes' to={toAbsoluteUrl('/Programme')} menuPlacement='bottom-start' menuTrigger='click'>
        {/* PAGES */}
        <MenuItem icon='profile-user' to={toAbsoluteUrl('/Programme/kindergarten')} title='Kindergarten'/>
        <MenuInnerWithSub
          title='Primary'
          to='/Programme/primary'
          icon='profile-user'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
        <MenuItem to={toAbsoluteUrl('/Programme/primary-1')} title='Primary 1' hasBullet={true}/>
        <MenuItem to={toAbsoluteUrl('/Programme/primary-2')} title='Primary 2' hasBullet={true}/>
        <MenuItem to={toAbsoluteUrl('/Programme/primary-3')} title='Primary 3' hasBullet={true}/>
        <MenuItem to={toAbsoluteUrl('/Programme/primary-4')} title='Primary 4' hasBullet={true}/>
        <MenuItem to={toAbsoluteUrl('/Programme/primary-5')} title='Primary 5' hasBullet={true}/>
        <MenuItem to={toAbsoluteUrl('/Programme/primary-6')} title='Primary 6' hasBullet={true}/>
        </MenuInnerWithSub>
        <MenuInnerWithSub
          title='Secondary'
          to='/Programme/secondary'
          icon='profile-user'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
        <MenuItem to={toAbsoluteUrl('/Programme/secondary-1')} title='Secondary 1' hasBullet={true}/>
        <MenuItem to={toAbsoluteUrl('/Programme/secondary-2')} title='Secondary 2' hasBullet={true}/>
        <MenuItem to={toAbsoluteUrl('/Programme/secondary-3')} title='Secondary 3' hasBullet={true}/>
        <MenuItem to={toAbsoluteUrl('/Programme/secondary-4')} title='Secondary 4' hasBullet={true}/>
        <MenuItem to={toAbsoluteUrl('/Programme/secondary-5')} title='Secondary 5' hasBullet={true}/>
        </MenuInnerWithSub>
        <MenuItem icon='profile-user' to={toAbsoluteUrl('/Programme/ite')} title='ITE' />
        <MenuItem icon='profile-user' to={toAbsoluteUrl('/Programme/poly')} title='Poly' />
        <MenuItem icon='profile-user' to={toAbsoluteUrl('/Programme/jc')} title='JC' />
        <MenuItem icon='profile-user' to={toAbsoluteUrl('/Programme/private')} title='Private' />
      </MenuInnerWithSub>
      <MenuItem title='Partners' to='/Partners' />
      <MenuItem title='Shop' to='/Shop' />
      {currentUser &&
      <MenuItem title='QR Code Scanner' to='/QrScanner' />
      }
    </>
  )
}

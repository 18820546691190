import * as Yup from 'yup'

export interface IResetPwd {
  password1: string
  password2: string
}

const resetPasswordSchemas = [
  Yup.object({
    password1: Yup.string().required().label('Password 1'),
    password2: Yup.string().required().label('Password 2 (Repeat)'),
  }),
]

const inits: IResetPwd = {
  password1: '',
  password2: '',
}

export {resetPasswordSchemas, inits}

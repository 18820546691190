import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { BuilderPageShop} from './BuilderPageShop'
import {GetShopItem,GetShopCart,SearchContent} from '../../modules/auth/core/_requests'
import React, {useState,useEffect} from 'react'
import LoadingAnimation from '../../LoadingAnimation'

const aboutBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/About Us',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const ShopPageWrapper: FC = () => {
  const [xdata, setXdata] = useState<any[]>([]);
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [xdata2, setXdata2] = useState<any[]>([]);

  const fetchContents = async () => {
    setLoading2(true);
    const {data: res} = await SearchContent("ShopTitle")
    setXdata2(res);
    setLoading2(false);
  };
  const fetchData = async () => {
    setLoading(true);

    const {data: xcontent } = await GetShopItem();

    console.log(xcontent)
    setXdata(xcontent);
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
    fetchContents()
  }, []);
  return (
    <>
      <PageTitle breadcrumbs={aboutBreadCrumbs}>Shop</PageTitle>
      <BuilderPageShop xdata={xdata} loading={loading} xdata2={xdata2} loading2={loading2}/>
    </>
  )
}

export default ShopPageWrapper

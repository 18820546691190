import Spinner from 'react-bootstrap/Spinner';

function LoadingAnimation() {
  return (
    <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
      <div className='col-xl-12 text-center p-10'>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </div>
    </div>
  );
}

export default LoadingAnimation;
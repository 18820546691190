/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const handleSignout=()=>{
    logout()
    document.location.replace("/dashboard")
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            { currentUser?.usrPhotoBase64 == "" ?
            <img title={currentUser?.usrFirstname + " " + currentUser?.usrLastname} src={toAbsoluteUrl('/media/images/noimage.jpg')} />
            :
            <img title={currentUser?.usrFirstname + " " + currentUser?.usrLastname} src={currentUser?.usrPhotoBase64} />
            }
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5'>
             {currentUser?.usrFirstname} {currentUser?.usrLastname}
              <span className='badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2'>{currentUser?.usrRoleName}</span>
            </div>
            <a href='#' className='fw-semibold text-muted text-hover-primary fs-7'>
              {currentUser?.usrEmail}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to={toAbsoluteUrl('/pages/MyProfile')} className='menu-link px-5'>
        <i className="ki-duotone ki-user fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;My Profile
        </Link>
      </div>

    <div className='menu-item px-5'>
        <a href={toAbsoluteUrl('/pages/MyProfile/ChangePassword')} className='menu-link px-5'>
          <span className='menu-text'><i className="ki-duotone ki-key fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;Change Password</span>
        </a>
      </div>
{/*
      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'><i className="ki-duotone ki-document fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;My Volunteer</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
          <a href={toAbsoluteUrl('/pages/MyProfile/RewardsPoints')} className='menu-link px-5'>
        Rewards Points
        </a>
          </div>

{/*           <div className='menu-item px-3'>
          <Link to={toAbsoluteUrl('/pages/MyProfile/VolunteerHistory')} className='menu-link px-5'>
        Volunteering History
        </Link>
          </div> */}

{/*           <div className='menu-item px-3'>
          <Link to={toAbsoluteUrl('/pages/MyProfile/Roles')} className='menu-link px-5'>
        Roles
        </Link>
          </div> */}
{/*           <div className='menu-item px-3'>
          <Link to={toAbsoluteUrl('/pages/MyProfile/HoursVolunteered')} className='menu-link px-5'>
        Hours Volunteered
        </Link>
          </div> */}
          <div className='menu-item px-3'>
          <Link to={toAbsoluteUrl('/pages/MyProfile/AttendanceHistory')} className='menu-link px-5'>
        Attendance History
        </Link>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <a onClick={() => { handleSignout() }} className='menu-link px-5'>
        <i className="ki-duotone ki-exit-left fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}

import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { BuilderPagexTnc } from './BuilderPagexTnc'
import {useAuth} from '../../../app/modules/auth'
import {GetContentBySection,SearchContent} from '../../modules/auth/core/_requests'
import React, {useState,useEffect} from 'react'
import LoadingAnimation from '../../LoadingAnimation'
const tncBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TncPageWrapper: FC = () => {
  const [xdata, setXdata] = useState([]);
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [xdata2, setXdata2] = useState<any[]>([]);

  const fetchContents = async () => {
    setLoading2(true);
    const {data: res} = await SearchContent("TncTitle")
    setXdata2(res);
    setLoading2(false);
  };
  const fetchData = async () => {
    setLoading(true);

    const {data: xcontent } = await GetContentBySection("Tnc");

    console.log(xcontent)
    setXdata(xcontent[0]);
    setLoading(false);
  };
  //alert(xpath)
  useEffect(() => {
    fetchData();
    fetchContents()
  }, []);
  const {currentUser} = useAuth()
  return (
    <>
      <PageTitle breadcrumbs={tncBreadCrumbs}>&nbsp;&nbsp;Terms and conditions</PageTitle>
      {loading && <LoadingAnimation/>}
      <BuilderPagexTnc xdata={xdata} xdata2={xdata2} loading2={loading2}/>
    </>
  )
}

export default TncPageWrapper

import {FC, useMemo, useRef} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {GetContentBySection,SearchContent,CheckPermissions} from '../../modules/auth/core/_requests'
import React, {useState,useEffect} from 'react'
import LoadingAnimation from '../../LoadingAnimation'
import {UpdateContent} from '../../modules/auth/core/_requests';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Form, Modal } from 'react-bootstrap';
import $ from 'jquery';
import {useAuth} from '../../../app/modules/auth'
import { KTIcon } from '../../../_metronic/helpers'

const aboutBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/About Us',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const AboutPageWrapper: FC = () => {
  const [xdata, setXdata] = useState([]);
  const [loading, setLoading] = useState(false)
	const [modalShow, setModalShow] = useState(false);
	const [contentId, setContentId] = useState(0);
	const [contentSection, setContentSection] = useState("");
	const [contentDesc, setContentDesc] = useState("");
	const Swal = require('sweetalert2')
	const {currentUser} = useAuth()
  const [loading2, setLoading2] = useState(false)
  const [xdata2, setXdata2] = useState<any[]>([]);

          // ============================ start check permission ============================
          const [loadingPerms, setLoadingPerms] = useState(false);
          const refEdit = useRef(false);
      
          const fetchPerms = async () => {
              setLoadingPerms(true)
              const {data: resFunctionEdit} = await CheckPermissions(Number(currentUser?.usrRoleId),"contents","Update")
         
              refEdit.current = resFunctionEdit.statusFunction;
         
              setLoadingPerms(false)
          };
          // ============================ end check permission ============================

  const fetchContents = async () => {
    setLoading2(true);
    const {data: res} = await SearchContent("AboutTitle")
    setXdata2(res);
    setLoading2(false);
  };

  const handleContentEdit = (idx:number, ctSection:string,ctDesc:string) => {
		setModalShow(true)
		setContentId(idx)
		setContentSection(ctSection)
		setContentDesc(ctDesc)
	  };
    const modules = useMemo(
      () => ({
        toolbar: {
        container: [
          // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }], 
          ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'image'], 
          [{ color: [] }, { background: [] }, { align: [] }],
          ['clean'],
        ],
        },
      }),
      [],
      );
      const formats = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'code',
      'color',
      'background',
      'code-block',
      'align',
      ];
    const AddContentModal = (props:any) => {
      const [quillvalue, setQuillvalue] = useState('');
      console.log("halo :" + props.section)
      const handleQuillChange = (value:any) => {
        console.log(value);
        setQuillvalue(value)
      };
      const handleUpdate = async () => {
        try{
        $("button#btnUpdate").html("Please wait...")
        //alert($("#ctId").val());
        let ctIdx=Number($("#ctId").val()) as number;
        let ctSection=($("#ctSection").val()) as string;
        let ctContent=($("#ctContent").val()) as string;
        //alert(ctSection)
        const {data: res} = await UpdateContent(
          ctIdx,
          ctSection,
          quillvalue,
          currentUser?.usrId,
        )
        if(res.status == "success"){
          Swal.fire({
          title: "Success!",
          text: "Content is updated succesfully, please reload/refresh page to take effect",
          icon: "success",
          timer: 2000,
          showConfirmButton: false
          })
        }else{
          Swal.fire({
          title: "Error!",
          text: "Content is failed to update",
          icon: "error",
          timer: 2000,
          showConfirmButton: false
          })     
        }
        setModalShow(false)
        $("button#btnUpdate").html("Update")
        fetchData();
        } catch (error) {
        Swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          timer: 2000,
          showConfirmButton: false
        })
        $("button#btnUpdate").html("Update")  
        }
      }
      return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Edit Content
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <input type="hidden" defaultValue={props.id} id="ctId"/>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Section</Form.Label>
            {props.section != "" ?
              <Form.Control
              defaultValue={props.section}
              type="text"
              placeholder=""
              id="ctSection"
              autoFocus
              readOnly
              />
              :
              <Form.Control
              type="text"
              placeholder=""
              id="ctSection"
              autoFocus
            />
            }
            </Form.Group>
            <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
            >
            <Form.Label>Content</Form.Label>
            <ReactQuill theme="snow" modules={modules} formats={formats} defaultValue={contentDesc} id="ctQuill" onChange={handleQuillChange}/>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" id="btnUpdate" onClick={handleUpdate}>Update</Button>
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
        </Modal>
      );
    }
  const fetchData = async () => {
    setLoading(true);

    const {data: xcontent } = await GetContentBySection("AboutPage");

    console.log(xcontent)
    setXdata(xcontent[0]);
    setLoading(false);
  };
  const BuilderPageAbout = (props:any) => (
    <>
      <>
      <div className='card mb-10'>
        <div className='card-body d-flex align-items-center py-8'>
          {/* begin::Icon */}
          <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              className='text-primary h-75px w-75px h-lg-100px w-lg-100px position-absolute opacity-5'
            >
              <path
                fill='currentColor'
                d='M10.2,21.23,4.91,18.17a3.58,3.58,0,0,1-1.8-3.11V8.94a3.58,3.58,0,0,1,1.8-3.11L10.2,2.77a3.62,3.62,0,0,1,3.6,0l5.29,3.06a3.58,3.58,0,0,1,1.8,3.11v6.12a3.58,3.58,0,0,1-1.8,3.11L13.8,21.23A3.62,3.62,0,0,1,10.2,21.23Z'
              ></path>
            </svg>
            <KTIcon iconName='information' className='fs-2x fs-lg-3x text-primary position-absolute' />
          </div>
          {/* end::Icon */}

          {/* begin::Description */}
          <div className='ms-6'>
            <h2>About Us</h2>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
              {props.loading2 && <LoadingAnimation/>}
              {props.xdata2.length > 0 && props.xdata2[0].ctContent}
            </p>
          </div>
          {/* end::Description */}
        </div>
      </div>
      <div className='card card-custom'>
        <p className='p-10'>
          {props.loading && <LoadingAnimation/>}
          {<div dangerouslySetInnerHTML={{ __html: props.xdata.ctContent }}></div>}
          {refEdit.current && <a className="btn btn-warning btn-sm" href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(props.xdata.ctId),props.xdata.ctSection,props.xdata.ctContent)}>Edit</a>}
        </p>
      </div>
      </>
    </>
  )
  useEffect(() => {
    fetchPerms()
    fetchData();
    fetchContents()
  }, []);
  return (
    <>
      <PageTitle breadcrumbs={aboutBreadCrumbs}>About Us</PageTitle>
      <BuilderPageAbout xdata={xdata} loading={loading} xdata2={xdata2} loading2={loading2}/>
      <AddContentModal
        id={contentId}
        section={contentSection}
        desc={contentDesc}
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
    </>
  )
}

export default AboutPageWrapper

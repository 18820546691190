/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useEffect, useMemo, useState} from 'react'
import {KTIcon,toAbsoluteUrl} from '../../../_metronic/helpers'
import {getLayout, ILayout, LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {AddToCart,GetShopCart,RemoveCartItem,SaveOrders} from '../../modules/auth/core/_requests'
import {useAuth} from '../../../app/modules/auth'
import LoadingAnimation from '../../LoadingAnimation'
import DataTable from 'react-data-table-component'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';

type Props = {
  xdata: any[]
  loading:boolean
  xdata2: any[]
  loading2:boolean
}
const NoData = (props:any) => {
  let mystyle = {
    display: "block",
  };
  if(props.xloading){
    mystyle = {
      display: "none",
    };   
  }
  return (
    <div className={`card mb-5 mb-xxl-8`} style={mystyle}>
    {/* begin::Body */}
    <div className='card-body'>
    <div className="overflow-auto pb-5">
    <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed min-w-lg-600px flex-shrink-0 p-6">
    <span className="svg-icon svg-icon-primary svg-icon-2x" style={{marginRight:"10px",marginTop:"10px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect x="0" y="0" width="24" height="24"/>
            <path d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M19.0710678,4.92893219 L19.0710678,4.92893219 C19.4615921,5.31945648 19.4615921,5.95262146 19.0710678,6.34314575 L6.34314575,19.0710678 C5.95262146,19.4615921 5.31945648,19.4615921 4.92893219,19.0710678 L4.92893219,19.0710678 C4.5384079,18.6805435 4.5384079,18.0473785 4.92893219,17.6568542 L17.6568542,4.92893219 C18.0473785,4.5384079 18.6805435,4.5384079 19.0710678,4.92893219 Z" fill="red" fill-rule="nonzero" opacity="0.3"/>
        </g>
      </svg>
    </span>
      <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
        <div className="mb-3 mb-md-0 fw-semibold">
          <h4 className="text-gray-900 fw-bold">Shop not available</h4>
          <div className="fs-6 text-gray-700 pe-7">Shop still don't have an item/s!</div>
        </div>
      </div>
    </div>
  </div>   
  </div>
  </div>   
  )
}
const BuilderPageShop: React.FC<Props> = ({xdata,loading,xdata2,loading2}) => {
  const [quillvalue, setQuillvalue] = useState('');
  const [checked, setChecked] = useState(false); 
  const [xdatacart, setXdatacart] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [showPage, setShowPage] = useState("items");
  const [itemtitle, setItemtitle] = useState("");
  const [itemdesc, setItemdesc] = useState("");
  const [xstop, setXstop] = useState("no");
  const [xtotalcart, setXtotalcart] = useState(0);
  const Swal = require('sweetalert2')
  const {currentUser} = useAuth()

  const handleQuillChange = (value:any) => {
    console.log(value);
    setQuillvalue(value)
  };
  const handleCollectChange = () => {
    setChecked(!checked); 
  };

  const fetchCart = async () => {

    const {data: xcontent } = await GetShopCart(currentUser?.usrId!);

    setXdatacart(xcontent);
    setTotalRows(xdatacart.length);
    let xtotcart=0;
    for (var i=0; i < xcontent.length; i++) {
      xtotcart = xtotcart + xcontent[i].totalPrice
    }
    setXtotalcart(xtotcart)
  };
  useEffect(() => {
    if(xstop == "no"){
      fetchCart();
    }
  });
  const handleClickViewCart = () => {
    if(totalRows == 0){
      Swal.fire({
        title: "Info!",
        html: "Your shopping cart is empty now!",
        icon: "info",
        timer: 3000,
        showConfirmButton: false
      })
      return        
    }
    setShowPage("cart")
  }
  const handleClickViewItems = () => {
    setShowPage("items")
  }
  const handleClickAddToCart=async (idx:number,name:string,qty:number,price:number) => {
    if(!currentUser){
      Swal.fire({
        title: "Info!",
        html: "Please login first to add item/s to cart",
        icon: "info",
        timer: 3000,
        showConfirmButton: false
      })
      return     
    }
    const {data: res} = await AddToCart(
      currentUser?.usrId,
      idx,
      name,
      1,
      price,
      (price*1)
    )
    if(res.status == "success"){
      Swal.fire({
        title: "Success!",
        html: "Item <strong>" + name + "</strong> succesfully added to cart!",
        icon: "success",
        timer: 3000,
        showConfirmButton: false
      })
    }else if(res.status == "already"){
        Swal.fire({
          title: "Info!",
          html: "Item <strong>" + name + "</strong> already added in cart!",
          icon: "info",
          timer: 3000,
          showConfirmButton: false
        })
    }else{
      Swal.fire({
        title: "Error!",
        html: "Item <strong>" + name + "</strong> failed to add",
        icon: "error",
        timer: 3000,
        showConfirmButton: false
      })     
    }
  }
  const ViewDetailModal = (props:any) => {
    const xhide=()=>{
      setXstop("no")
      setModalShow(false)
    }
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Description of {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <div className='fv-row mb-10'>
              {<p dangerouslySetInnerHTML={{ __html: props.desc }}></p>}
              </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-lg btn-secondary' onClick={() => xhide()}>Close</button>
        </Modal.Footer>
      </Modal>
    );
  }
  const handleClickViewItem=(e:any,title:string,desc:string) => {
    setXstop("yes")
    e.preventDefault();
    setItemdesc(desc)
    setItemtitle(title)
    setModalShow(true)
  }
  const handleDeleteClickShopItem=(itemId:string)=>{
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this cart item?",
      icon: "question",
      showConfirmButton:true,
      confirmButtonText: 'Yes, delete it!',
      showCancelButton:true
    })
    .then (async (willDelete: any) => {
      if (willDelete.isConfirmed) {
        const {data: res} = await RemoveCartItem(
          Number(currentUser?.usrId),
          itemId
        )
        if(res.status == "success"){
          Swal.fire({
            title: "Success!",
            text: "This cart item is deleted succesfully",
            icon: "success",
            timer: 2000,
            showConfirmButton: false
          })
        }else{
          Swal.fire({
            title: "Error!",
            text: "This cart item is failed to delete",
            icon: "error",
            timer: 2000,
            showConfirmButton: false
          })     
        }
      }
    });
  }
  const columnsx:any[] = useMemo(
    () => [
      {
        name: "Item Name",
        selector: (row: { itemName: string; }) => `${ row.itemName }`,
        sortable: true,
        style:{maxWidth: 264}
      },
      {
        name: "Quantity",
        selector: (row: { qty: number; }) => `${ row.qty }`,
        sortable: true,
        style:{maxWidth: 264}
      },
      {
        name: "Unit Price",
        selector: (row: { unitPrice: number; }) => `${ row.unitPrice }`,
        sortable: true,
      },
      {
        name: "Total",
        selector: (row: { totalPrice: number; }) => `${ row.totalPrice }`,
        sortable: true,
      },
      {
        name: "Actions",
        button: true,
        style: {
          textAlign: 'left', 
        },
        cell: ((row:any) => {
            return (<>
              <a title="Delete" href="#" onClick={(e) => handleDeleteClickShopItem(row.itemId)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"><i className="ki-duotone ki-trash text-danger fs-3"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></i></a>
              </>)
        }),
    }
    ],
    []
  );
  const handleClickCheckout=async () => {
    let addr=checked ? "N/A" : quillvalue
    let iscollect=checked ? true : false

    $("#btnSave").css("display","none")
    $("#btnProgress").css("display","block")

    const {data: res} = await SaveOrders(
      currentUser?.usrId,
      "N/A",
      false
    )
    if(res.status == "success"){
      $("#btnSave").css("display","block")
      $("#btnProgress").css("display","none")
      Swal.fire({
        title: "Success!",
        html: "Your orders has been placed!",
        icon: "success",
        timer: 3000,
        showConfirmButton: false
      }).then(() => {
          window.location.href = "/Shop";
      }) 
    }else if(res.status == "notenough"){
      $("#btnSave").css("display","block")
      $("#btnProgress").css("display","none")
      Swal.fire({
        title: "info!",
        text: "Your point is not enough to pay this transaction!",
        icon: "info",
        timer: 3000,
        showConfirmButton: false
      }) 
    }else{
      $("#btnSave").css("display","block")
      $("#btnProgress").css("display","none")
      Swal.fire({
        title: "Error!",
        text: "Your orders failed, please try again",
        icon: "error",
        timer: 3000,
        showConfirmButton: false
      })     
    }
  }
  return (
    <>
      <div className='card mb-10'>
        <div className='card-body d-flex align-items-center py-8'>
          {/* begin::Icon */}
          <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              className='text-primary h-75px w-75px h-lg-100px w-lg-100px position-absolute opacity-5'
            >
              <path
                fill='currentColor'
                d='M10.2,21.23,4.91,18.17a3.58,3.58,0,0,1-1.8-3.11V8.94a3.58,3.58,0,0,1,1.8-3.11L10.2,2.77a3.62,3.62,0,0,1,3.6,0l5.29,3.06a3.58,3.58,0,0,1,1.8,3.11v6.12a3.58,3.58,0,0,1-1.8,3.11L13.8,21.23A3.62,3.62,0,0,1,10.2,21.23Z'
              ></path>
            </svg>
            <KTIcon iconName='shop' className='fs-2x fs-lg-3x text-primary position-absolute' />
          </div>
          {/* end::Icon */}

          {/* begin::Description */}
          <div className='ms-6'>
            <h2>Shop</h2>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
              {loading2 && <LoadingAnimation/>}
              {xdata2.length > 0 && xdata2[0].ctContent}
              {currentUser &&
              <>
              {showPage == "items" ?
              <><br /><br /><button onClick={() => handleClickViewCart()} type="button" className="btn btn-light-primary me-3">
                    <i className="ki-duotone ki-handcart fs-2">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>Shopping Cart ({totalRows})</button></>
              :
              <><br /><br /><button onClick={() => handleClickViewItems()} type="button" className="btn btn-light-primary me-3">
              <i className="ki-duotone ki-grid fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>Back to Shopping</button></>             
              }
              </>
              } 
            </p>
          </div>
          {/* end::Description */}
        </div>
      </div>
      <div className='card card-custom'>
        <div className='row'>
        {loading && <LoadingAnimation/>}
        {xdata.length > 0 ?
            <>
            {showPage == "items" ?
              xdata.map((({id,name,description,quantity,price,imageBase64}:any) => {
                return <div className="col-md-3">
                  <div className="card card-flush h-xl-100">
                    <div className="card-body text-center pb-5">
                      <a className="d-block overlay" data-fslightbox="lightbox-hot-sales" href="#">
                        <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded mb-7" style={{height: "266px",backgroundImage:"url(" + imageBase64 + ")"}}></div>
                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                          <i className="bi bi-eye-fill fs-2x text-white"></i>
                        </div>
                      </a>
                      <div className="d-flex align-items-end flex-stack mb-1">
                        <div className="text-start">
                          <span className="fw-bold text-gray-800 cursor-pointer text-hover-primary fs-4 d-block">{name}</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-end flex-stack mb-1">
                        <span className="text-gray-400 fw-bold fs-6">Price (Point) :</span>
                        <span className="badge py-3 px-4 fs-7 badge-light-success">{price}</span>
                      </div>
                      <div className="d-flex flex-stack mb-1">
                        <span className="text-gray-400 fw-bold fs-6">In-Stock :</span>
                        {quantity == 0 ?
                        <span className="badge py-3 px-4 fs-7 badge-light-danger">Out of Stock</span>
                        :
                        <span className="badge py-3 px-4 fs-7 badge-light-success">{quantity}</span>
                        }
                      </div>
                    </div>
                    <div className="card-footer d-flex flex-stack pt-0">
                      {quantity == 0 ?
                      <button disabled className="btn btn-sm btn-primary flex-shrink-0 me-2" onClick={() => handleClickAddToCart(id,name,quantity,price)}>Add To Cart</button>
                      :
                      <button className="btn btn-sm btn-primary flex-shrink-0 me-2" onClick={() => handleClickAddToCart(id,name,quantity,price)}>Add To Cart</button>
                      }
                      <button className="btn btn-sm btn-light flex-shrink-0" onClick={(e) => handleClickViewItem(e,name,description)}>Description</button>
                    </div>
                  </div>
                </div>
                }))
              :
              <div className="col-md-12">
                <div className="card card-flush h-xl-100">
                  <div className="card-body text-center pb-5">
                    <h3>Shopping Cart</h3>
                    <DataTable
                      noDataComponent="Shopping Cart are empty"
                      columns={columnsx}
                      data={xdatacart}
                      defaultSortFieldId="ctSection"
                      pagination
                    />
                    {totalRows > 0 &&
                                    <>
  {/*                       {!checked &&
                        <div className='row mt-10'>
                          <div className='col-md-6'></div>
                        <div className='col-md-6' style={{textAlign:"left"}}>
                          <span className='mb-5'>Delivery Address :</span>
                          <ReactQuill theme="snow" id="ctQuill" onChange={handleQuillChange} />
                        </div>
                      </div>
                        }  */}
                      <div style={{ float: "right", width: "395px", marginTop: "33px", lineHeight: "45px" }}>
                          <div className='row mb-5'>
                            <div className="col-md-6" style={{padding: "12px"}}>
                              <label className="form-check form-switch form-check-custom form-check-solid">
                                <h3>Total : {xtotalcart} point</h3>
                              </label>
                            </div>
  {/*                           <div className="col-md-4">
                              <label className="form-check form-switch form-check-custom form-check-solid">
                                <input className="form-check-input" name="billing" type="checkbox" value="1" id="kt_modal_add_customer_billing" onChange={handleCollectChange}/>
                                <span className="form-check-label fw-semibold text-muted">Collect</span>
                              </label>
                            </div> */}
                            <div className="col-md-6" style={{ textAlign: "right" }}>
                              <button id='kt_sign_up_submit' onClick={() => handleClickCheckout()} type="button" className="btn btn-lg btn-danger me-3">
                              <span className='indicator-label' id="btnSave">Checkout</span>
                              <span className='indicator-progress' id="btnProgress">
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                              </button>
                            </div>
                          </div>
                        </div></>
                    }
                  </div>
                </div>
              </div>
            }
            </>
            :
            <NoData xloading={loading}/>
          }
        </div>
      </div>
      <ViewDetailModal
        title={itemtitle}
        desc={itemdesc}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}

export {BuilderPageShop}

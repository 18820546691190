/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState} from 'react'
import * as Yup from 'yup'
import {useFormik,Form, Formik, FormikValues,ErrorMessage, Field} from 'formik'
import {resetPasswordSchemas, IResetPwd, inits} from './ResetPasswordHelper';
import {useAuth} from '../../../app/modules/auth'
import {UpdatePasswordByToken} from '../../modules/auth/core/_requests';
import clsx from 'clsx'

type Props = {
  token: string
}
export const ResetPassword: React.FC<Props> = ({token}) => {
  const [loading, setLoading] = useState(false)
  const [currentSchema, setCurrentSchema] = useState(resetPasswordSchemas[0])
  const [initValues] = useState<IResetPwd>(inits)
  const Swal = require('sweetalert2')
  const {currentUser} = useAuth()

  const initialValues = {
    xtoken : token,
    password1: '',
    password2: '',
  }
  const registrationSchema = Yup.object().shape({
      password1: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
      password2: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password confirmation is required')
      .oneOf([Yup.ref('password1')], "Password and Confirm Password didn't match"),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        const {data: res} = await UpdatePasswordByToken(
          values.xtoken,
          values.password1,
        )
        if(res.status == "success"){
          Swal.fire({
            title: "Success!",
            text: "Your password is changed succesfully",
            icon: "success",
            timer: 2000,
            showConfirmButton: false
          })
          setLoading(false)
        }else{
          Swal.fire({
            title: "Error!",
            text: "Your password is failed to change",
            icon: "error",
            timer: 2000,
            showConfirmButton: false
          })     
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        Swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          timer: 2000,
          showConfirmButton: false
        })
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  return (
    <>
        <div className={`card mb-5 mb-xxl-8`}>
      {/* begin::Body */}
      <div className='card-body'>
            <form className='w-100 w-xl-700px px-9' noValidate id='kt_create_account_form' onSubmit={formik.handleSubmit}>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center form-label'>
                  <span className='required'>Password 1</span>
                </label>
                <input type='hidden' defaultValue={token} {...formik.getFieldProps('xtoken')}/>
                <input
                  type='password'
                  placeholder='Password 1'
                  autoComplete='off'
                  {...formik.getFieldProps('password1')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password1 && formik.errors.password1,
                    },
                    {
                      'is-valid': formik.touched.password1 && !formik.errors.password1,
                    }
                  )} />
                {formik.touched.password1 && formik.errors.password1 && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password1}</span>
                    </div>
                  </div>
                )}
                <div className='form-text'>
                  Please fill with your new password
                </div>
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center form-label'>
                  <span className='required'>Password 2</span>
                </label>

                <input
                  type='password'
                  placeholder='Password 2'
                  autoComplete='off'
                  {...formik.getFieldProps('password2')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password2 && formik.errors.password2,
                    },
                    {
                      'is-valid': formik.touched.password2 && !formik.errors.password2,
                    }
                  )} />
                {formik.touched.password2 && formik.errors.password2 && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password2}</span>
                    </div>
                  </div>
                )}

                <div className='form-text'>
                  Password 2 is for confirmation (must same with password 1)
                </div>
              </div>
              <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Reset Password</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            </form>
        </div></div>
    </>
  )
}

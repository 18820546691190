import axios, { AxiosResponse } from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/VerifyToken`
export const LOGIN_URL = `${API_URL}/Login`
export const REGISTER_URL = `${API_URL}/Register`
export const REQUEST_PASSWORD_URL = `${API_URL}/ForgotPassword`
export const GET_ALL_USER = `${API_URL}/GetAllUser`
export const GET_ALL_CONTENT = `${API_URL}/GetAllContent`
export const GET_ALL_PROGRAMME = `${API_URL}/GetAllProgrammes`
export const GET_ALL_PROGRAMME_ADMIN = `${API_URL}/GetAllProgrammesAdmin`
export const GET_REWARDS = `${API_URL}/GetRewards`
export const GET_VOLUNTEERING_HISTORY = `${API_URL}/GetVolunteeringHistory`
export const GET_HOURS_VOLUNTEERED = `${API_URL}/GetHoursVolunteered`
export const GET_ATTENDANCE = `${API_URL}/GetAttendanceHistoryByMember`
export const SEARCH_ATTENDANCE = `${API_URL}/SearchAttendanceHistoryByMember`
export const GET_EVENTS = `${API_URL}/GetEvents`
export const GET_PARTNERS = `${API_URL}/GetPartners`
export const GET_REPORTS = `${API_URL}/GetReports`

export const GET_ROLES = `${API_URL}/GetRoles`
export const UPDATE_ROLES = `${API_URL}/UpdateRoles`
export const DELETE_ROLES = `${API_URL}/DeleteRoles`
export const SEARCH_ROLES = `${API_URL}/SearchRoles`
export const SAVE_ROLES = `${API_URL}/SaveRoles`

export const SAVE_CONTENT_URL = `${API_URL}/SaveContent`
export const UPDATE_CONTENT_URL = `${API_URL}/UpdateContent`
export const DELETE_CONTENT_URL = `${API_URL}/DeleteContent`
export const SEARCH_CONTENT_URL = `${API_URL}/SearchContent`
export const GET_CONTENT_URL = `${API_URL}/GetContentBySection`

export const SAVE_PARTNER_URL = `${API_URL}/SavePartner`
export const UPDATE_PARTNER_URL = `${API_URL}/UpdatePartner`
export const DELETE_PARTNER_URL = `${API_URL}/DeletePartner`
export const SEARCH_PARTNER_URL = `${API_URL}/SearchPartner`

export const SAVE_USER_URL = `${API_URL}/SaveUser`
export const UPDATE_USER_URL = `${API_URL}/UpdateUser`
export const DELETE_USER_URL = `${API_URL}/DeleteUser`
export const UPDATE_PASSWORD_URL = `${API_URL}/UpdatePassword`
export const UPDATE_PASSWORD_BYTOKEN = `${API_URL}/UpdatePasswordByToken`
export const SEARCH_USER_URL = `${API_URL}/SearchUser`
export const VERIFY_USER_URL = `${API_URL}/VerifyUser`

export const SAVE_PROGRAMME_URL = `${API_URL}/SaveProgramme`
export const SAVE_PARTNERPROGRAMME_URL = `${API_URL}/SavePartnerProgramme`
export const UPDATE_PROGRAMME_URL = `${API_URL}/UpdateProgramme`
export const DELETE_PROGRAMME_URL = `${API_URL}/DeleteProgramme`
export const SEARCH_PROGRAMME_URL = `${API_URL}/SearchProgramme`
export const GET_PROGRAMME_URL = `${API_URL}/GetProgrammeByCat`
export const GET_RANDOM_PROGRAMME = `${API_URL}/GetRandomProgrammes`

export const SAVE_EVENT_URL = `${API_URL}/SaveEvent`
export const UPDATE_EVENT_URL = `${API_URL}/UpdateEvent`
export const DELETE_EVENT_URL = `${API_URL}/DeleteEvent`
export const SEARCH_EVENT_URL = `${API_URL}/SearchEvent`

export const UPDATE_USERINFO_URL = `${API_URL}/UpdateUserInfo`
export const UPDATE_USERDETAIL_URL = `${API_URL}/UpdateUserDetail`
export const GET_USERINFO_URL = `${API_URL}/GetUserInfo`
export const GET_LOGININFO_URL = `${API_URL}/GetLoginInfo`
export const GET_ACTIVATE_URL = `${API_URL}/ActivateAccount`
export const GET_DEACTIVATE_URL = `${API_URL}/DeactivateAccount`

export const GET_SETREG_URL = `${API_URL}/SetRegProgramme`
export const GET_GETREG_URL = `${API_URL}/GetRegProgramme`

export const SAVE_ACCOUNTINFO_URL = `${API_URL}/SaveAccountInfo`

export const GET_VOLUNTEERS = `${API_URL}/GetVolunteer`
export const GET_TUITIONS = `${API_URL}/GetTuition`
export const GET_MEMBERSHIPS = `${API_URL}/GetMembership`
export const GET_ENROLMENT = `${API_URL}/GetEnrolment`
export const GET_ATTACHMENT = `${API_URL}/GetProfileAttachment`
export const GET_ADDITIONAL = `${API_URL}/GetProfileAdditional`

export const SAVE_IMAGESLIDER = `${API_URL}/SaveImageSlider`
export const GET_IMAGESLIDER = `${API_URL}/GetAllImageSlider`
export const DELETE_SLIDER_URL = `${API_URL}/DeleteSlider`

export const SAVE_VOLUNTEER_REG = `${API_URL}/SaveVolunteerReg`
export const SAVE_VOLUNTEER_MANUALREG = `${API_URL}/SaveVolunteerManualReg`

export const GET_ALL_MEMBERSHIP = `${API_URL}/GetAllMembership`
export const GET_ALL_TUITION = `${API_URL}/GetAllTuition`
export const GET_ALL_VOLUNTEER = `${API_URL}/GetAllVolunteer`

export const SAVE_TESTIMONIAL = `${API_URL}/SaveTestimonial`
export const UPDATE_TESTIMONIAL = `${API_URL}/UpdateTestimonial`
export const GET_TESTIMONIAL = `${API_URL}/GetTestimonial`
export const GET_ALL_TESTIMONIAL = `${API_URL}/GetAllTestimonial`
export const DELETE_TESTIMONIAL = `${API_URL}/DeleteTestimonial`
export const GET_RANDOM_TESTIMONIAL = `${API_URL}/GetRandomTestimonial`
export const UPDATE_REG_PROGRAMME = `${API_URL}/UpdateRegProgramme`
export const GET_REG_PROGRAMME_UPDATE = `${API_URL}/GetRegProgrammeUpdate`

export const GET_ATTENDANCE_BY_PROID = `${API_URL}/GetAttendanceHistoryByProId`
export const APPROVE_USER_URL = `${API_URL}/ApproveUser`

export const SAVE_PROGRAM_CATEGORY = `${API_URL}/SaveProgramCategory`
export const GET_PROGRAM_CATEGORY = `${API_URL}/GetProgramCategory`
export const SEARCH_PROGRAM_CATEGORY = `${API_URL}/SearchProgramCategory`
export const GET_COMBO_BOX = `${API_URL}/SearchComboBox`

export const DELETE_COMBO_BOX = `${API_URL}/DeleteComboBox`
export const DELETE_PROGRAM_CATEGORY = `${API_URL}/DeleteProgCat`
export const DELETE_REG = `${API_URL}/DeleteReg`

export const SEARCH_SHOP_ITEM = `${API_URL}/SearchShopItem`
export const SEARCH_ALLSHOP_ITEM = `${API_URL}/SearchAllShopItems`
export const DELETE_SHOP_ITEM = `${API_URL}/DeleteShopItem`
export const GET_SHOP_ITEM = `${API_URL}/GetShopItems`
export const GET_ALLSHOP_ITEM = `${API_URL}/GetAllShopItems`

export const SEARCH_SHOP_TRX = `${API_URL}/SearchShopTransaction`
export const DELETE_SHOP_TRX = `${API_URL}/DeleteShopTrx`
export const GET_SHOP_TRX = `${API_URL}/GetShopTransaction`

export const ADD_TO_CART= `${API_URL}/AddToCart`
export const GET_SHOP_CART= `${API_URL}/GetShoppingCart`
export const REMOVE_CART_ITEM= `${API_URL}/RemoveCartItem`
export const SAVE_ORDERS= `${API_URL}/SaveOrders`
export const GET_ORDER_DETAIL= `${API_URL}/GetOrderDetail`
export const UPDATE_ORDER_STATUS= `${API_URL}/UpdateOrderStatus`

export const GET_SHOP_TRX_BYID= `${API_URL}/GetShopTransactionById`
export const SEARCH_SHOP_TRX_BYID= `${API_URL}/SearchShopTransactionById`
export const SAVE_REWARD= `${API_URL}/SaveReward`

export const SAVE_INFRINGEMENT_IN= `${API_URL}/SaveInfringementIn`
export const SAVE_INFRINGEMENT_OUT= `${API_URL}/SaveInfringementOut`
export const GET_INFRINGEMENT= `${API_URL}/GetInfringement`
export const GET_ALL_INFRINGEMENT= `${API_URL}/GetAllInfringement`
export const GET_REPORT_VIEW= `${API_URL}/GetReportView`
export const GET_PROGRAMME_REG= `${API_URL}/GetProgrammeRegistration`
export const SEARCH_PROGRAMME_REG= `${API_URL}/SearchProgrammeRegistration`

export const DELETE_VOLUNTEER_CONTACT= `${API_URL}/DeleteVolunteerInfo`
export const DELETE_PARTICIPANT_CONTACT= `${API_URL}/DeleteMembershipInfo`
export const DELETE_TUITION_CONTACT= `${API_URL}/DeleteTuitionInfo`

export const SEARCH_ALL_TUITION= `${API_URL}/SearchAllTuition`
export const SEARCH_ALL_MEMBERSHIP= `${API_URL}/SearchAllMembership`
export const SEARCH_ALL_VOLUNTEER= `${API_URL}/SearchAllVolunteer`
export const GET_TUTOR_LIST= `${API_URL}/GetAllVolunteerUser`
export const SAVE_QUIZ= `${API_URL}/SaveQuizAssessment`

export const GET_DATA_COMPLEATION= `${API_URL}/GetDataCompleation`
export const GET_USERS_CHART_DATA= `${API_URL}/GetUsersChartData`
export const GET_NEW_ATT_CHART_DATA= `${API_URL}/GetNewAttChartData`
export const GET_UNIQUE_ATT_CHART_DATA= `${API_URL}/GetUniqueAttChartData`
export const GET_LATEST_PARTNER= `${API_URL}/GetLatestPartner`

export const DELETE_PIMAGE_URL = `${API_URL}/DeleteProgrammeImage`

export const CHECK_PERMISSIONS= `${API_URL}/CheckPermissions`
export const GET_ATT_BYDATE= `${API_URL}/GetAllInfringementByDate`
export const HIDESHOWN_TESTIMONIAL= `${API_URL}/UpdateTestimonialShown`
export const VIEW_REPORT_ATT= `${API_URL}/ViewReportAttByProgramme`

export const DELETE_ENROLMENT_URL = `${API_URL}/DeleteEnrolment`
export const CHANGE_ENROLMENT_URL = `${API_URL}/ChangeEnrolment`
export const CHANGE_ENROLMENTDATE_URL = `${API_URL}/ChangeEnrolmentDate`

export const DELETE_PROFILE_ATTACHMENT = `${API_URL}/AttachmentDelete`
export const CHANGE_COLLECT_STATUS = `${API_URL}/ChangeCollectStatus`

export function ChangeCollectStatus(
  xid: number | undefined,
) {
  return axios.post(CHANGE_COLLECT_STATUS, {
    Id: xid,
  })
}

export function DeleteProfileAttachment(
  xid: number | undefined,
) {
  return axios.post(DELETE_PROFILE_ATTACHMENT, {
    Id: xid,
  })
}

export function DeleteEnrolment(
  xid: number | undefined,
) {
  return axios.post(DELETE_ENROLMENT_URL, {
    Id: xid,
  })
}
export function ChangeEnrolmentDate(eid: number,newenroldate:string) {
  return axios.get(CHANGE_ENROLMENTDATE_URL +  "/" + eid + "/" + newenroldate);
}
export function ChangeEnrolment(eid: number,pxid:number,pid:number) {
  return axios.get(CHANGE_ENROLMENT_URL +  "/" + eid + "/" + pxid + "/" + pid);
}

export function ViewReportAttByProgramme(pid: number,year:string,startdate:string) {
  return axios.get(VIEW_REPORT_ATT +  "/" + pid + "/" + year + "/" + startdate);
}

export function UpdateTestimonialShown(
  xid: number | undefined,
  ishide:boolean
) {
  return axios.post(HIDESHOWN_TESTIMONIAL, {
    Id: xid,
    IsShown:ishide
  })
}

export function ValidateQrCode(
  strval: string,
) {
  return axios.post(`${API_URL}/ValidateQrCode`, {
    xval: strval,
  })
}

export function GetAttByDate(pid: number,xdate:string) {
  return axios.get(GET_ATT_BYDATE + "/" + pid + "/" + xdate);
}

export function CheckPermissions(rid: number,rmodule:string,rfunction:string) {
  return axios.get(CHECK_PERMISSIONS + "/" + rid + "/" + rmodule + "/" + rfunction);
}

export function DeleteProgrammeImage(
  idx: number,
) {
  return axios.post(DELETE_PIMAGE_URL, {
    Id: idx,
  })
}
export const GET_USER_PERMS= `${API_URL}/GetUserRoleList`
export function GetUserRoleList(rid: number) {
  return axios.get(GET_USER_PERMS + "/" + rid);
}

export const GET_PROGRAMMES_IMAGE= `${API_URL}/GetProgrammeImage`
export function GetProgrammeImage(pid: number) {
  return axios.get(GET_PROGRAMMES_IMAGE + "/" + pid);
}
// start filters request
export const FILTERS_PROGRAMME= `${API_URL}/FilterProgrammes`
export const FILTERS_USER= `${API_URL}/FilterAllUser`
export const FILTERS_EVENT= `${API_URL}/FilterEvents`
export const FILTERS_REPORT= `${API_URL}/FilterReports`
export const PROCEED_RECURRING= `${API_URL}/ProceedAllRecurring`

// user roles
export const SAVE_USER_ROLES= `${API_URL}/SaveUserRoles`
export const GET_USER_ROLES= `${API_URL}/GetAllUserRoles`
export const UPDATE_USER_ROLES= `${API_URL}/UpdateUserRoles`
export const DELETE_USER_ROLES= `${API_URL}/DeleteUserRoles`

export const UPDATE_LEADER= `${API_URL}/UpdateLeader`
export const UPDATE_TASEK= `${API_URL}/UpdateTasek`
export const GET_CUSTOM_COLUMN= `${API_URL}/GetCustomColumn`
export const GET_CUSTOM_COLUMN_EXCEL= `${API_URL}/GetCustomColumnExcel`
export const GET_SESSION= `${API_URL}/GetSession`
export const DELETE_ATT= `${API_URL}/DeleteAtt`

export function DeleteAtt(uid:number,pid:number,dtx:string) {
  return axios.get(DELETE_ATT + "/" + uid + "/" + pid + "/" + dtx);
}

export function GetSession(pid:number,year:string) {
  return axios.get(GET_SESSION + "/" + pid + "/" + year);
}

export function GetCustomColumnExcel(
  valx: string,
) {
  return axios.post(GET_CUSTOM_COLUMN_EXCEL, {
    xVal: valx,
  })
}

export function GetCustomColumn() {
  return axios.get(GET_CUSTOM_COLUMN);
}

export function UpdateLeader(
  idx:number,
  islead:boolean,
) {
  return axios.post(UPDATE_LEADER, {
    Id:idx,
    IsLeader: islead,
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
export function UpdateTasek(
  idx:number,
  istasek:boolean,
) {
  return axios.post(UPDATE_TASEK, {
    usrId:idx,
    usrIsTasek: istasek,
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
export function SaveUserRoles(
  rNameId:number,
  rModule:string,
  rPerms:string,
  rcreated:number
) {
  return axios.post(SAVE_USER_ROLES, {
    roleNameId:rNameId,
    roleModule: rModule,
    rolePermissions:rPerms,
    roleCreatedBy:rcreated
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
export function UpdateUserRoles(
  rId:number,
  rNameId:number,
  rModule:string,
  rPerms:string,
  rcreated:number
) {
  return axios.post(UPDATE_USER_ROLES, {
    roleId:rId,
    roleNameId:rNameId,
    roleModule: rModule,
    rolePermissions:rPerms,
    roleCreatedBy:rcreated
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
export function GetAllUserRoles() {
  return axios.get(GET_USER_ROLES);
}
export function DeleteUserRoles(
  rid: number,
) {
  return axios.post(DELETE_USER_ROLES, {
    roleId: rid,
  })
}

// user roles name
export const SAVE_USER_ROLES_NAME= `${API_URL}/SaveUserRolesName`
export const GET_USER_ROLES_NAME= `${API_URL}/GetAllUserRolesName`
export const UPDATE_USER_ROLES_NAME= `${API_URL}/UpdateUserRolesName`
export const DELETE_USER_ROLES_NAME= `${API_URL}/DeleteUserRolesName`

export const IMPORT_ATTENDANCE= `${API_URL}/GetAllProgrammesAtt`
export function GetAllProgrammesAtt() {
  return axios.get(IMPORT_ATTENDANCE);
}

export const CHECK_TOKEN= `${API_URL}/CheckResetPasswordToken`
export function CheckResetPasswordToken(xtoken:string) {
  return axios.get(CHECK_TOKEN + "/" + xtoken);
}

export function SaveUserRolesName(
  namex:string,
) {
  return axios.post(SAVE_USER_ROLES_NAME, {
    Name: namex,
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
export function UpdateUserRolesName(
  idx:string,
  namex:string,
) {
  return axios.post(UPDATE_USER_ROLES_NAME, {
    Id:idx,
    Name: namex,
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
export function GetAllUserRolesName() {
  return axios.get(GET_USER_ROLES_NAME);
}
export function DeleteUserRolesName(
  rid: number,
) {
  return axios.post(DELETE_USER_ROLES_NAME, {
    Id: rid,
  })
}

export function ProceedAllRecurring() {
  return axios.get(PROCEED_RECURRING);
}
export function FilterProgrammes(
  x1:string,
  x2:string,
  x3:string
) {
  return axios.post(FILTERS_PROGRAMME, {
    bycategory:x1,
    bytype: x2,
    byorg:x3
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
export function FilterAllUser(
  x1:string,
  x2:string,
  x3:string
) {
  return axios.post(FILTERS_USER, {
    byrole:x1,
    byveri: x2,
    bystatus:x3
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
export function FilterEvents(
  x1:string,
  x2:string
) {
  return axios.post(FILTERS_EVENT, {
    bycategory:x1,
    byorg: x2
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
export function FilterReports(
  x1:string,
  x2:string
) {
  return axios.post(FILTERS_REPORT, {
    bycategory:x1,
    bytype: x2
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
//end filters request

export function GetLatestPartner(limit: string) {
  return axios.get(GET_LATEST_PARTNER + "/" + limit);
}
export function GetNewAttChartData() {
  return axios.get(GET_NEW_ATT_CHART_DATA);
}
export function GetUniqueAttChartData() {
  return axios.get(GET_UNIQUE_ATT_CHART_DATA);
}
export function GetUsersChartData() {
  return axios.get(GET_USERS_CHART_DATA);
}
export function GetDataCompleation() {
  return axios.get(GET_DATA_COMPLEATION);
}

export function SaveQuizAssessment(
  q1:number,
  a1:number,
  q2:number,
  a2:number,
  q3:number,
  a3:number,
  q4:number,
  a4:number,
  uid:number,
  pid:number,
  xcreateid:number | undefined
) {
  return axios.post(SAVE_QUIZ, {
    UserId:uid,
    ProgrammeId: pid,
    T1dq:q1,
    T1wa: a1,
    T2dq:q2,
    T2wa: a2,
    T3dq:q3,
    T3wa: a3,
    T4dq:q4,
    T4wa: a4,
    CreatedBy:xcreateid
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
export function GetAllVolunteerUser() {
  return axios.get(GET_TUTOR_LIST);
}
export function SearchAllTuition(kw: string) {
  return axios.get(SEARCH_ALL_TUITION + "/" + kw);
}
export function SearchAllMembership(kw: string) {
  return axios.get(SEARCH_ALL_MEMBERSHIP + "/" + kw);
}
export function SearchAllVolunteer(kw: string) {
  return axios.get(SEARCH_ALL_VOLUNTEER + "/" + kw);
}
export function DeleteVolunteerInfo(
  uid: number,
) {
  return axios.post(DELETE_VOLUNTEER_CONTACT, {
    UserId: uid,
  })
}
export function DeleteMembershipInfo(
  uid: number,
) {
  return axios.post(DELETE_PARTICIPANT_CONTACT, {
    UserId: uid,
  })
}
export function DeleteTuitionInfo(
  uid: number,
) {
  return axios.post(DELETE_TUITION_CONTACT, {
    UserId: uid,
  })
}

export function SearchProgrammeRegistration(cat: string) {
  return axios.get(SEARCH_PROGRAMME_REG + "/" + cat);
}
export function GetProgrammeRegistration() {
  return axios.get(GET_PROGRAMME_REG);
}
export function GetReportView(pid:any) {
  return axios.get(GET_REPORT_VIEW + "/" + pid);
}
export function GetAllInfringement(pid:string,pxid:string,xyear:string) {
  return axios.get(GET_ALL_INFRINGEMENT + "/" + pid+ "/" + pxid + "/" + xyear);
}
export function GetInfringement(uid: string,pid:string,pxid:string) {
  return axios.get(GET_INFRINGEMENT + "/" + uid + "/" + pid);
}
export function SaveInfringementIn(
  xuid:number,
  xpid:number,
  xdate:Date,
) {
  return axios.post(SAVE_INFRINGEMENT_IN, {
    UserId:xuid,
    ProgrammeId: xpid,
    CheckIn: xdate,
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
export function SaveInfringementOut(
  xuid:number,
  xpid:number,
  xdate:Date,
) {
  return axios.post(SAVE_INFRINGEMENT_OUT, {
    UserId:xuid,
    ProgrammeId: xpid,
    CheckOut: xdate,
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
export function SaveReward(
  xuid:number,
  xuidx:number,
  xpid:number,
  xpartid:number,
  xpoint:number,
  xhours:number,
) {
  return axios.post(SAVE_REWARD, {
    UserId:xuidx,
    CreatedBy:xuid,
    PartnerId: xpartid,
    ProgrammeId: xpid,
    Point:xpoint,
    NumbHours:xhours, 
  },{
    headers: {
      'Content-Type': 'application/json'
    }  
  })
}
export function GetOrderDetail(reff: string) {
  return axios.get(GET_ORDER_DETAIL + "/" + reff);
}
export function SaveOrders(
  uid:number | undefined,
  addr: string,
  iscoll: boolean,
) {
  return axios.post(SAVE_ORDERS, {
    UserId:uid,
    DeliveryAddress:addr,
    IsCollect: iscoll,
  },{
    headers: {
      'Content-Type': 'application/json'
    }   
  })
}
export function UpdateOrderStatus(
  xstatus:number | undefined,
  xreff: string,
) {
  return axios.post(UPDATE_ORDER_STATUS, {
    TransReff:xreff,
    Status: xstatus,
  },{
    headers: {
      'Content-Type': 'application/json'
    }   
  })
}

export function RemoveCartItem(uid: number,itemId:string) {
  return axios.get(REMOVE_CART_ITEM + "/" + uid + "/" + itemId);
}

export function GetShopCart(uid: number) {
  return axios.get(GET_SHOP_CART + "/" + uid);
}
export function AddToCart(
  xuserid: number | undefined,
  xitemid: number | undefined,
  xitemname: string,
  xqty: number,
  xunitprice: number,
  xtotalprice: number,
) {
  return axios.post(ADD_TO_CART, {
    UserId:xuserid,
    ItemId: xitemid,
    ItemName: xitemname,
    Qty: xqty,
    UnitPrice: xunitprice,
    TotalPrice: xtotalprice,
  })
}
// =========================================================
export function SearchShopTrx(cat: string) {
  return axios.get(SEARCH_SHOP_TRX + "/" + cat);
}
export function DeleteShopTrx(
  reff: string,
) {
  return axios.post(DELETE_SHOP_TRX, {
    TransReff: reff,
  })
}
export function GetShopTrx() {
  return axios.get(GET_SHOP_TRX);
}
export function GetShopTrxById(uid: number) {
  return axios.get(GET_SHOP_TRX_BYID + "/" + uid);
}
export function SearchShopTrxById(uid: number,cat: string) {
  return axios.get(SEARCH_SHOP_TRX_BYID + "/" + uid + "/" + cat);
}
// =========================================================
// =========================================================
export function SearchShopItem(cat: string) {
  return axios.get(SEARCH_SHOP_ITEM + "/" + cat);
}
export function SearchAllShopItems(cat: string) {
  return axios.get(SEARCH_ALLSHOP_ITEM + "/" + cat);
}
export function DeleteShopItem(
  xid: number | undefined,
) {
  return axios.post(DELETE_SHOP_ITEM, {
    Id: xid,
  })
}
export function GetShopItem() {
  return axios.get(GET_SHOP_ITEM);
}
export function GetAllShopItem() {
  return axios.get(GET_ALLSHOP_ITEM);
}
// =========================================================
export function DeleteReg(
  xid: number | undefined,
) {
  return axios.post(DELETE_REG, {
    Id: xid,
  })
}
export function SearchProgramCategory(cat: string) {
  return axios.get(SEARCH_PROGRAM_CATEGORY + "/" + cat);
}
export function DeleteComboBox(
  xid: number | undefined,
) {
  return axios.post(DELETE_COMBO_BOX, {
    ctId: xid,
  })
}
export function DeleteProgCat(
  xid: number | undefined,
) {
  return axios.post(DELETE_PROGRAM_CATEGORY, {
    ctId: xid,
  })
}
export function GetProgramCategory() {
  return axios.get(GET_PROGRAM_CATEGORY);
}
export function GetComboBox(cat: string) {
  return axios.get(GET_COMBO_BOX + "/" + cat);
}
export function ApproveUser(
  xid: number | undefined,
) {
  return axios.post(APPROVE_USER_URL, {
    Id: xid,
  })
}
export function GetAttendanceHistoryByProId(pid: number,xyear:string) {
  return axios.get(GET_ATTENDANCE_BY_PROID + "/" + pid + "/" + xyear);
}

export function GetRegProgrammeUpdate(uid: string) {
  return axios.get(GET_REG_PROGRAMME_UPDATE + "/" + uid);
}
export function UpdateRegProgramme(uid: string,xsetfor:string) {
  return axios.get(UPDATE_REG_PROGRAMME + "/" + uid + "/" + xsetfor);
}
export function GetRandomTestimonial() {
  return axios.get(GET_RANDOM_TESTIMONIAL);
}
export function DeleteTestimonial(
  xid: number | undefined,
) {
  return axios.post(DELETE_TESTIMONIAL, {
    Id: xid,
  })
}

export function GetAllTestimonial() {
  return axios.get(GET_ALL_TESTIMONIAL);
}
export function GetTestimonial(uid: string) {
  return axios.get(GET_TESTIMONIAL + "/" + uid);
}
export function UpdateTestimonial(
  uid:number | undefined,
  xtitle: string,
  xcontent: string,
  createdby: number | undefined,
) {
  return axios.post(UPDATE_TESTIMONIAL, {
    Id: uid,
    Title: xtitle,
    Contentx: xcontent,
    CreatedBy:createdby
  },{
    headers: {
      'Content-Type': 'application/json'
    }   
  })
}
export function SaveTestimonial(
  uid:number | undefined,
  xtitle: string,
  xcontent: string,
  rating: number,
  proid:number,
  createdby: number | undefined,
) {
  return axios.post(SAVE_TESTIMONIAL, {
    UserId: uid,
    ProgrammeId: proid,
    Title: xtitle,
    Contentx: xcontent,
    Rate: rating,
    CreatedBy:createdby
  },{
    headers: {
      'Content-Type': 'application/json'
    }   
  })
}
// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.get<AuthModel>(LOGIN_URL + "/" + email + "/" + password);
}

// Server should return AuthModel
export function register(
  email: string,
  phone: string,
  firstname: string,
  lastname: string,
  password: string,
) {
  return axios.post(REGISTER_URL, {
    usrEmail:email,
    usrPhone:phone,
    usrFirstname: firstname,
    usrLastname: lastname,
    usrPassword: password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.get<{result: boolean}>(REQUEST_PASSWORD_URL + "/" + email);
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL + "/" + token);
}
export function getAllUser() {
  return axios.get(GET_ALL_USER);
}
export const getAllUserx = (): Promise<UserModel> => {
  return axios
    .get(GET_ALL_USER)
    .then((d: AxiosResponse<UserModel>) => d.data)
}
export function getAllContent() {
  return axios.get(GET_ALL_CONTENT);
}
export function GetAllProgrammes() {
  return axios.get(GET_ALL_PROGRAMME);
}
export function GetAllProgrammesAdmin() {
  return axios.get(GET_ALL_PROGRAMME_ADMIN);
}
export function GetRewards(uid: number) {
  return axios.get(GET_REWARDS + "/" + uid);
}
export function GetVolunteeringHistory(uid: number) {
  return axios.get(GET_VOLUNTEERING_HISTORY + "/" + uid);
}
export function GetHoursVolunteered(uid: number) {
  return axios.get(GET_HOURS_VOLUNTEERED + "/" + uid);
}
export function GetAttendanceHistory(uid: number) {
  return axios.get(GET_ATTENDANCE + "/" + uid);
}
export function SearchAttendanceHistory(uid: number,kw:string) {
  return axios.get(SEARCH_ATTENDANCE + "/" + uid + "/" + kw);
}
export function GetEvents() {
  return axios.get(GET_EVENTS);
}
export function GetPartners() {
  return axios.get(GET_PARTNERS);
}
export function GetReports() {
  return axios.get(GET_REPORTS);
}
export function SaveContent(
  xsection: string,
  xcontent: string,
  xuserid: number | undefined,
) {
  return axios.post(SAVE_CONTENT_URL, {
    ctSection: xsection,
    ctContent: xcontent,
    ctCreatedBy: xuserid,
  })
}
export function UpdateContent(
  xid: number | undefined,
  xsection: string,
  xcontent: string,
  xuserid: number | undefined,
) {
  return axios.post(UPDATE_CONTENT_URL, {
    ctId: xid,
    ctSection: xsection,
    ctContent: xcontent,
    ctUpdatedBy: xuserid,
  })
}
export function DeleteContent(
  xid: number | undefined,
) {
  return axios.post(DELETE_CONTENT_URL, {
    ctId: xid,
  })
}
export function SearchContent(kw: string) {
  return axios.get(SEARCH_CONTENT_URL + "/" + kw);
}

export function SavePartner(
  xname: string,
  xaddress: string,
  xcontact: string,
  regtype: string,
  desc: string,
  xuserid: number | undefined,
) {
  return axios.post(SAVE_PARTNER_URL, {
    Name: xname,
    Address: xaddress,
    ContactInfo: xcontact,
    RegType: regtype,
    Description: desc,
    CreatedBy: xuserid,
  })
}
export function UpdatePartner(
  xid: number | undefined,
  xname: string,
  xaddress: string,
  xcontact: string,
  regtype: string,
  desc: string,
  xuserid: number | undefined,
) {
  return axios.post(UPDATE_PARTNER_URL, {
    Id: xid,
    Name: xname,
    Address: xaddress,
    ContactInfo: xcontact,
    RegType: regtype,
    Description: desc,
    UpdatedBy: xuserid,
  })
}
export function DeletePartner(
  xid: number | undefined,
) {
  return axios.post(DELETE_PARTNER_URL, {
    Id: xid,
  })
}
export function SearchPartner(kw: string) {
  return axios.get(SEARCH_PARTNER_URL + "/" + kw);
}
export function UpdateUser(
  xid: number | undefined,
  xfname: string,
  xlname: string,
  xemail: string,
  xphone: string,
  xroleid:Number
) {
  return axios.post(UPDATE_USER_URL, {
    usrId: xid,
    usrFirstname: xfname,
    usrLastname: xlname,
    usrEmail: xemail,
    usrPhone: xphone,
    usrRoleId: xroleid,
  })
}
export function SaveUser(
  xfname: string,
  xlname: string,
  xemail: string,
  xphone: string,
  xpassword: string,
  xroleid:Number
) {
  return axios.post(SAVE_USER_URL, {
    usrFirstname: xfname,
    usrLastname: xlname,
    usrEmail: xemail,
    usrPhone: xphone,
    usrPassword: xpassword,
    usrRoleId: xroleid,
  })
}
export function DeleteUser(
  xid: number | undefined,
) {
  return axios.post(DELETE_USER_URL, {
    usrId: xid,
  })
}
export function UpdatePassword(
  xid: number | undefined,
  xpassword: string,
) {
  return axios.post(UPDATE_PASSWORD_URL, {
    usrId: xid,
    usrPassword: xpassword,
  })
}
export function UpdatePasswordByToken(
  xtoken: string,
  xpassword: string,
) {
  return axios.post(UPDATE_PASSWORD_BYTOKEN, {
    usrToken: xtoken,
    usrPassword: xpassword,
  })
}
export function SearchUser(kw: string) {
  return axios.get(SEARCH_USER_URL + "/" + kw);
}
export function VerifyUser(
  xid: number | undefined,
) {
  return axios.post(VERIFY_USER_URL, {
    usrId: xid,
  })
}
export function SaveProgramme(
  prosetfor: string,
  procat: string,
  protitle: string,
  prodesc: string,
  prodays: string,
  protimestart: string,
  protimeend: string,
  profee: number | undefined,
  proorgname: string,
  procapacity: number | undefined,
  procapacity2: number | undefined,
  prostartdate: Date,
  proenddate: Date,
  xuserid: number | undefined,
  prohours: number | undefined,
  propoint: number | undefined,
  prohours2: number | undefined,
  propoint2: number | undefined,
  prorecurring:boolean,
  prorepeatmode:string,
  proendsby:string,
  proendsbytanggal:Date,
  proendsbyoccurs:number,
  xismonday:boolean,
  xisthursday:boolean,
  xiswednesday:boolean,
  xistuesday:boolean,
  xisfriday:boolean,
  xissaturday:boolean,
  xissunday:boolean,
  xstarttime:string,
  xendtime:string
) {
  return axios.post(SAVE_PROGRAMME_URL, {
    proSetFor: prosetfor,
    proCat: procat,
    proTitle: protitle,
    proDesc: prodesc,
    proDays: prodays,
    proTimeStart: protimestart,
    proTimeEnd: protimeend,
    proFee: profee,
    proStartDate: prostartdate,
    proEndDate: proenddate,
    proOrgName: proorgname,
    proCapacity: procapacity,
    proCapacity2: procapacity2,
    proCreatedBy: xuserid,
    proHours: prohours,
    proPoint: propoint,
    proHours2: prohours2,
    proPoint2: propoint2,
    proIsRecurrent:prorecurring,
    proRepeatMode:prorepeatmode,
    proEndsBy:proendsby,
    proEndsOn:proendsbytanggal,
    proEndsAfter:proendsbyoccurs,
    isMonday:xismonday,
    isThursday:xisthursday,
    isWednesday:xiswednesday,
    isTuesday:xistuesday,
    isFriday:xisfriday,
    isSaturday:xissaturday,
    isSunday:xissunday,
    startTime:xstarttime,
    endTime:xendtime,
  })
}
export function SavePartnerProgramme(
  propartnerid: number,
  prosetfor: string,
  procat: string,
  protitle: string,
  prodesc: string,
  prodays: string,
  protimestart: string,
  protimeend: string,
  profee: number | undefined,
  proorgname: string,
  procapacity: number | undefined,
  procapacity2: number | undefined,
  prostartdate: Date,
  proenddate: Date,
  xuserid: number | undefined,
  prohours: number | undefined,
  propoint: number | undefined,
  prohours2: number | undefined,
  propoint2: number | undefined,
  prorecurring:boolean,
  prorepeatmode:string,
  proendsby:string,
  proendsbytanggal:Date,
  proendsbyoccurs:number,
  xismonday:boolean,
  xisthursday:boolean,
  xiswednesday:boolean,
  xistuesday:boolean,
  xisfriday:boolean,
  xissaturday:boolean,
  xissunday:boolean,
  xstarttime:string,
  xendtime:string
) {
  return axios.post(SAVE_PARTNERPROGRAMME_URL, {
    proPartnerId: propartnerid,
    proSetFor: prosetfor,
    proCat: procat,
    proTitle: protitle,
    proDesc: prodesc,
    proDays: prodays,
    proTimeStart: protimestart,
    proTimeEnd: protimeend,
    proFee: profee,
    proStartDate: prostartdate,
    proEndDate: proenddate,
    proOrgName: proorgname,
    proCapacity: procapacity,
    proCapacity2: procapacity2,
    proCreatedBy: xuserid,
    proHours: prohours,
    proPoint: propoint,
    proHours2: prohours2,
    proPoint2: propoint2,
    proIsRecurrent:prorecurring,
    proRepeatMode:prorepeatmode,
    proEndsBy:proendsby,
    proEndsOn:proendsbytanggal,
    proEndsAfter:proendsbyoccurs,
    isMonday:xismonday,
    isThursday:xisthursday,
    isWednesday:xiswednesday,
    isTuesday:xistuesday,
    isFriday:xisfriday,
    isSaturday:xissaturday,
    isSunday:xissunday,
    startTime:xstarttime,
    endTime:xendtime, 
  })
}
export function UpdateProgramme(
  xid: number | undefined,
  prosetfor: string,
  procat: string,
  protitle: string,
  prodesc: string,
  prodays: string,
  protimestart: string,
  protimeend: string,
  profee: number | undefined,
  proorgname: string,
  procapacity: number | undefined,
  procapacity2: number | undefined,
  prostartdate: Date,
  proenddate: Date,
  xuserid: number | undefined,
  prohours: number | undefined,
  propoint: number | undefined,
  prohours2: number | undefined,
  propoint2: number | undefined,
  prorecurring:boolean,
  prorepeatmode:string,
  proendsby:string,
  proendsbytanggal:Date,
  proendsbyoccurs:number,  

  xismonday:boolean,
  xisthursday:boolean,
  xiswednesday:boolean,
  xistuesday:boolean,
  xisfriday:boolean,
  xissaturday:boolean,
  xissunday:boolean,
  xstarttime:string,
  xendtime:string
) {
  return axios.post(UPDATE_PROGRAMME_URL, {
    proId: xid,
    proSetFor: prosetfor,
    proCat: procat,
    proTitle: protitle,
    proDesc: prodesc,
    proDays: prodays,
    proTimeStart: protimestart,
    proTimeEnd: protimeend,
    proFee: profee,
    proOrgName: proorgname,
    proCapacity: procapacity,
    proCapacity2: procapacity2,
    proStartDate: prostartdate,
    proEndDate: proenddate,
    proUpdatedBy: xuserid,
    proHours: prohours,
    proPoint: propoint,
    proHours2: prohours2,
    proPoint2: propoint2,
    proIsRecurrent:prorecurring,
    proRepeatMode:prorepeatmode,
    proEndsBy:proendsby,
    proEndsOn:proendsbytanggal,
    proEndsAfter:proendsbyoccurs,

    isMonday:xismonday,
    isThursday:xisthursday,
    isWednesday:xiswednesday,
    isTuesday:xistuesday,
    isFriday:xisfriday,
    isSaturday:xissaturday,
    isSunday:xissunday,
    startTime:xstarttime,
    endTime:xendtime,
  })
}
export function DeleteProgramme(
  xid: number | undefined,
) {
  return axios.post(DELETE_PROGRAMME_URL, {
    proId: xid,
  })
}
export function SearchProgramme(kw: string) {
  return axios.get(SEARCH_PROGRAMME_URL + "/" + kw);
}

export function SaveEvent(
  proid: number | undefined,
  xuserid: number | undefined,
) {
  return axios.post(SAVE_EVENT_URL, {
    ProgrammeId: proid,
    CreatedBy: xuserid,
  })
}
export function UpdateEvent(
  xid: number | undefined,
  proid: number | undefined,
  orgname: string,
  xuserid: number | undefined,
) {
  return axios.post(UPDATE_EVENT_URL, {
    Id: xid,
    ProgrammeId: proid,
    OrgName: orgname,
    UpdatedBy: xuserid,
  })
}
export function DeleteEvent(
  xid: number | undefined,
) {
  return axios.post(DELETE_EVENT_URL, {
    Id: xid,
  })
}
export function SearchEvent(kw: string) {
  return axios.get(SEARCH_EVENT_URL + "/" + kw);
}
export function UpdateUserDetail(
  idx:number | undefined,
  fname: string,
  lname: string,
  email: string,
  phone: string,
) {
  return axios.post(UPDATE_USERDETAIL_URL, {
    usrId:idx,
    usrFirstname: fname,
    usrLastname: lname,
    usrEmail: email,
    usrPhone: phone,
  })
}
export function UpdateUserInfo(
  xaccupation: string,
  xinterest: string,
) {
  return axios.post(UPDATE_USERINFO_URL, {
    About: xaccupation,
    Accupation: xinterest,
  })
}
export function GetUserInfo(uid: number) {
  return axios.get(GET_USERINFO_URL + "/" + uid);
}
export function GetLoginInfo(uid: number) {
  return axios.get(GET_LOGININFO_URL + "/" + uid);
}
export function ActivateAccount(
  xid: number | undefined,
) {
  return axios.post(GET_ACTIVATE_URL, {
    usrId: xid,
  })
}
export function DeactivateAccount(
  xid: number | undefined,
) {
  return axios.post(GET_DEACTIVATE_URL, {
    usrId: xid,
  })
}
export function GetProgrammeByCat(xcat: string) {
  return axios.get(GET_PROGRAMME_URL + "/" + xcat);
}
export function GetContentBySection(xsec: string) {
  return axios.get(GET_CONTENT_URL + "/" + xsec);
}
export function SetRegProgramme(
  idx:number | undefined,
  proid: number | undefined,
  prosetfor:string,
  procat: string,
  protitle: string,
  prodesc: string,
  prodays: string,
  protimestart: string,
  protimeend: string,
  profee: number | undefined,
  proorgname: string,
  prostartdate: Date,
  proenddate: Date,
  prohours: number | undefined,
  propoint: number | undefined,
) {
  return axios.post(GET_SETREG_URL, {
    uId: idx,
    proId: proid,
    proSetFor: prosetfor,
    proCat: procat,
    proTitle: protitle,
    proDesc: prodesc,
    proDays: prodays,
    proTimeStart: protimestart,
    proTimeEnd: protimeend,
    proFee: profee,
    proOrgName: proorgname,
    proStartDate: prostartdate,
    proEndDate: proenddate,
    proHours: prohours,
    proPoint: propoint,
  })
}
export function GetRegProgramme(uid: any) {
  return axios.get(GET_GETREG_URL + "/" + uid);
}
export function SaveAccountInfo(
  idx:number | undefined,
  accupation: string,
  friend: string,
  interest: string,
  education: string,
  certfile: File,
  experience: string,
  about: string,
) {
  return axios.post(SAVE_ACCOUNTINFO_URL, {
    UserId: idx,
    Accupation: accupation,
    Friend: friend,
    Interest: interest,
    Education: education,
    CertFile: certfile,
    VolunteerExperiences: experience,
    About: about,
  })
}
export function GetTuition(uid: any) {
  return axios.get(GET_TUITIONS + "/" + uid);
}
export function GetMembership(uid: any) {
  return axios.get(GET_MEMBERSHIPS + "/" + uid);
}
export function GetEnrolment(uid: any) {
  return axios.get(GET_ENROLMENT + "/" + uid);
}
export function GetAttachment(uid: any) {
  return axios.get(GET_ATTACHMENT + "/" + uid);
}
export function GetAdditional(uid: any) {
  return axios.get(GET_ADDITIONAL + "/" + uid);
}
export function GetVolunteer(uid: any) {
  return axios.get(GET_VOLUNTEERS + "/" + uid);
}
export function SaveImageSlider(
  idx:number | undefined,
  title: string,
  imgfile: File,
) {
  return axios.post(SAVE_IMAGESLIDER, {
    Title: title,
    ImgFile: imgfile,
    CreatedBy: idx,
  })
}
export function GetAllImageSlider() {
  return axios.get(GET_IMAGESLIDER);
}
export function DeleteSlider(
  xid: number | undefined,
) {
  return axios.post(DELETE_SLIDER_URL, {
    Id: xid,
  })
}
export function GetRandomProgrammes() {
  return axios.get(GET_RANDOM_PROGRAMME);
}
export function SaveVolunteerRegister(
  idx:number | undefined,
) {
  return axios.post(SAVE_VOLUNTEER_REG, {
    UserId:idx,
    CreatedBy: idx,
  })
}
export function SaveVolunteerManualRegister(
  xuid:number | undefined,
  xproid:number | undefined,
  xpartid:number | undefined,
  xregas:string | undefined,
  xcreatedby:number | undefined
) {
  return axios.post(SAVE_VOLUNTEER_MANUALREG, {
    UserId:xuid,
    ProgrammeId: xproid,
    PartnerId:xpartid,
    RegisterAs: xregas,
    CreatedBy: xcreatedby,
  })
}
export function GetAllTuition() {
  return axios.get(GET_ALL_TUITION);
}
export function GetAllMembership() {
  return axios.get(GET_ALL_MEMBERSHIP);
}
export function GetAllVolunteer() {
  return axios.get(GET_ALL_VOLUNTEER);
}

export function GetRoles(uid: number) {
  return axios.get(GET_ROLES + "/" + uid);
}
export function UpdateRoles(
  idx:number | undefined,
  orgname: string,
) {
  return axios.post(UPDATE_ROLES, {
    UserId:idx,
    OrgName: orgname,
    CreatedBy: idx,
  })
}
export function SaveRoles(
  idx:number | undefined,
  orgname: string,
) {
  return axios.post(SAVE_ROLES, {
    UserId:idx,
    OrgName: orgname,
    CreatedBy: idx,
  })
}
export function DeleteRoles(
  xid: number | undefined,
) {
  return axios.post(DELETE_ROLES, {
    Id: xid,
  })
}
export function SearchRoles(kw: string) {
  return axios.get(SEARCH_ROLES + "/" + kw);
}
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ValidateQrCode} from '../../modules/auth/core/_requests'
import { useEffect, useRef, useState } from "react";
import {useAuth} from '../../modules/auth'
// Styles
import "./QrStyles.css";

// Qr Scanner
import QrScanner from "qr-scanner";
import QrFrame from "./qr_frame.png";
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import axios from 'axios';

const aboutBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/QR Code Scanner',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const NoCompleted = () => {
  return (
    <div className={`card mb-2`}>
    {/* begin::Body */}
    <div className='card-body'>
    <div className="overflow-auto">
    <div className="notice d-flex bg-light-info rounded border-info border border-dashed min-w-lg-450px flex-shrink-0 p-6">
      <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
        <div className="mb-3 mb-md-0 fw-semibold">
          <h4 className="text-gray-900 fw-bold">Information</h4>
          <div className="fs-6 text-gray-700 pe-7">Please contact admin if there is scanning problem!</div>
        </div>
      </div>
    </div>
  </div>   
  </div>
  </div>   
  )
}
const QrCodePageWrapper: FC = () => {
  // QR States
  // QR States
  const {currentUser} = useAuth()

  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);

  // Result
  const [scannedStatus, setScannedStatus] = useState<string>("<span class=\"badge py-3 px-4 fs-4 badge-light-info\">Please scan your QR Code here!</span>");
  const [scannedResult, setScannedResult] = useState<string | undefined>("");
  const [scannedResultName, setScannedResultName] = useState<string | undefined>("");
  const [scannedResultProgramme, setScannedResultProgramme] = useState<string | undefined>("");
  //const [isScanned, setIsScanned] = useState<boolean | undefined>(false);
  const Swal = require('sweetalert2')

  // Success
  const onScanSuccess = async (result: QrScanner.ScanResult) => {
    const API_URL = process.env.REACT_APP_API_URL;
    // 🖨 Print the "result" to browser console.
    console.log(result);
    // ✅ Handle success.
    // 😎 You can do whatever you want with the scanned result.

    try {
      let formData=new FormData();
      formData.append("Token",result?.data);
      formData.append("UserId",Number(currentUser?.usrId).toString());
      
      //console.log("formData : " + SAVE_ACCOUNTINFO_URL)
      await axios.post(`${API_URL}/ValidateQrCode`, formData)
      .then(function (res) { // then print response status
          console.warn(res.data);
          if(res.data.status == "success"){
/*             Swal.fire({
              title: "Success!",
              text: "Your QR Code is validated succesfully!",
              icon: "success",
              timer: 3000,
              showConfirmButton: false
            }) */
            setScannedStatus("<span class=\"badge py-3 px-4 fs-2 badge-light-success\">Passed</span><br/><a className=\"btn btn-sm btn-secondary\" href=\"/pages/MyProfile/AttendanceHistory\">[ View Attendance History ]</a>")
            setScannedResultName(res.data.fullname)
            setScannedResultProgramme(res.data.proName)
          }else if(res.data.status == "notreg"){
            setScannedStatus("<span class=\"badge py-3 px-4 fs-2 badge-light-danger\">Not Registered yet to this programme!</span>")
            setScannedResultName(res.data.fullname)
            setScannedResultProgramme(res.data.proName)
          }else if(res.data.status == "na"){
            setScannedStatus("<span class=\"badge py-3 px-4 fs-2 badge-light-danger\">Class not available!</span>")
            setScannedResultName(res.data.fullname)
            setScannedResultProgramme(res.data.proName)
          }else{
            setScannedResultName(res.data.fullname)
            setScannedResultProgramme(res.data.proName)
            setScannedStatus("<span class=\"badge py-3 px-4 fs-2 badge-light-danger\">Failed to validate the QR Code!</span>")    
          }
      })
    } catch (error) {
      console.error(error)
      setScannedStatus("<span class=\"badge py-3 px-4 fs-2 badge-light-danger\">" + error + "</span>") 
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        //timer: 5000,
        showConfirmButton: true
      })
    }
    setScannedResult(result?.data);
  };

  // Fail
  const onScanFail = (err: string | Error) => {
    // 🖨 Print the "err" to browser console.
    console.log(err);
  };

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      // 👉 Instantiate the QR Scanner
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: "environment",
        // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
        overlay: qrBoxEl?.current || undefined,
      });

      // 🚀 Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  // ❌ If "camera" is not allowed in browser permissions, show an alert.
  useEffect(() => {
    if (!qrOn)
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);
  return (
    <>
      <PageTitle breadcrumbs={aboutBreadCrumbs}>QR Code Scanner</PageTitle>
      <div className='card mb-10'>
        <div className='card-body d-flex align-items-center py-8'>
          {/* begin::Icon */}
          <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              className='text-primary h-75px w-75px h-lg-100px w-lg-100px position-absolute opacity-5'
            >
              <path
                fill='currentColor'
                d='M10.2,21.23,4.91,18.17a3.58,3.58,0,0,1-1.8-3.11V8.94a3.58,3.58,0,0,1,1.8-3.11L10.2,2.77a3.62,3.62,0,0,1,3.6,0l5.29,3.06a3.58,3.58,0,0,1,1.8,3.11v6.12a3.58,3.58,0,0,1-1.8,3.11L13.8,21.23A3.62,3.62,0,0,1,10.2,21.23Z'
              ></path>
            </svg>
            <KTIcon iconName='shop' className='fs-2x fs-lg-3x text-primary position-absolute' />
          </div>
          {/* end::Icon */}

          {/* begin::Description */}
          <div className='ms-6'>
            <h2>QR Code Scanner for attendance</h2>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
              Please use this page to scan your QR Code
            </p>
          </div>
          {/* end::Description */}
        </div>
      </div>
      <div className='card card-custom'>
        <div className='row'>
          <div className='col-md-6'>
              <div className="qr-reader">
              {/* QR */}
              <video ref={videoEl}></video>
              <div ref={qrBoxEl} className="qr-box">
              <img
                  src={QrFrame}
                  alt="Qr Frame"
                  className="qr-frame"
                /> 
              </div>

              {/* Show Data Result if scan is success */}
{/*               <p
                  style={{
                    position: "absolute",
                    top: "580px",
                    left: 0,
                    zIndex: 99999,
                    color: "black",
                  }}
                >
                  Scanned Result: {scannedResult ? scannedResult : "N/A"}
                </p> */}
{/*               {scannedResult && (
                <p
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 99999,
                    color: "black",
                  }}
                >
                  Scanned Result: {scannedResult}
                </p>
              )} */}
            </div> 
          </div> 
          <div className='col-md-6 qr-info-box'>     
              {<p style={{textAlign:"center"}} dangerouslySetInnerHTML={{ __html: scannedStatus }}></p>}
              Full Name : <strong>{scannedResult ? scannedResultName : "N/A"}</strong> <br/>
              Programme Name : <strong>{scannedResult ? scannedResultProgramme : "N/A"}</strong>
              <NoCompleted/>
              <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/images.png')}
          />
          </div>
        </div>
      </div>
    </>
  )
}

export default QrCodePageWrapper


import {lazy, FC, Suspense, useState, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import { BevolunteerWrapper } from '../pages/bevolunteer/BevolunteerWrapper'
import { matchRoutes, useLocation } from "react-router-dom"
import PartnersPageWrapper from '../pages/partners/PartnersPageWrapper'
import TncPageWrapper from '../pages/termsconditions/TncPageWrapper'
import { Logout, useAuth } from '../modules/auth'
import { AuthPage2 } from '../modules/auth/AuthPage2'
import AboutPageWrapper from '../pages/aboutus/AboutPageWrapper'
import ContactPageWrapper from '../pages/contact/ContactPageWrapper'
import ProfilePageDashboard from '../modules/profilevmsadmin/ProfilePageDashboard'
import ShopPageWrapper from '../pages/shop/ShopPageWrapper'
import {SearchProgramCategory} from '../modules/auth/core/_requests'
import QrCodePageWrapper from '../pages/qrcode/QrCodePageWrapper'
import ResetPasswordPageWrapper from '../pages/resetpassword/ResetPasswordPageWrapper'

const PrivateRoutes = () => {
  const ProfilePageVms = lazy(() => import('../modules/profilevms/ProfilePage'))
  const ProfilePageVmsAdmin = lazy(() => import('../modules/profilevmsadmin/ProfilePageAdmin'))
  const ProfilePageVmsAdminDashboard = lazy(() => import('../modules/profilevmsadmin/ProfilePageDashboard'))
  const ContactInfoList=lazy(() => import('../modules/profilecontact/ProfileContact'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const ProgrammePage = lazy(() => import('../modules/programme/ProgrammePage'))
  const [xdatacat, setXdatacat] = useState<any[]>([]);
  const [loading, setLoading] = useState(false)
  const {currentUser, logout} = useAuth()

  const location = useLocation()
  let xpath = location.pathname;
  //alert(xpath)
  let xnamevar=xpath.split("/")[xpath.split("/").length -1].toLowerCase();
  //let xnamevar="kindergarten";
//alert(xnamevar)
/*   let xpath = location.pathname;
  let xnamevar=xpath.split("/")[xpath.split("/").length -1];
  let xname="";
  let ximage="";
  if(xnamevar.toLowerCase() == "kindergarten"){
    xname="Kindergarten";
    ximage="kindergaten.jpg";
  }else if(xnamevar.toLowerCase() == "primary1and2"){
    xname="Primary 1 And 2";
    ximage="primary12.jpeg";
  }else if(xnamevar.toLowerCase() == "primary36"){
    xname="Primary 3-6";
    ximage="primary36.jpg";
  }else if(xnamevar.toLowerCase() == "secondary15"){
    xname="Secondary 1-5";
    ximage="secondary.jpeg";
  }else if(xnamevar.toLowerCase() == "itepolyjcprivate"){
    xname="ITE-Poly-JC-Private";
    ximage="ite.jpg";
  } */
/*   const fetchData = async (xprog:string) => {
    setLoading(true);

    const {data: xcontentprog } = await SearchProgramCategory(xprog);
    setXdatacat(xcontentprog);

    setLoading(false);
  };
  useEffect(() => {
    fetchData(xnamevar.toLowerCase()); 
    console.log("gandhi : " + xdatacat);
  }, []); */

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {currentUser ? 
        <>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='/*' element={<Navigate to='/dashboard' />} />
        </>
        :
        <>
        <Route path='/*' element={<Navigate to='/dashboard' />} />
        </>
        }
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='BeAVolunteer' element={<BevolunteerWrapper/>} />
        <Route path='TermAndConditions' element={<TncPageWrapper/>} />
        <Route path='Partners' element={<PartnersPageWrapper/>} />
        <Route path='LoginPage' element={<AuthPage2/>} />
        <Route path='AboutUs' element={<AboutPageWrapper/>} />
        <Route path='Contact' element={<ContactPageWrapper/>} />
        <Route path='auth/*' element={<AuthPage2 />} />
        <Route path='logout' element={<Logout/>} />
        <Route path='Shop' element={<ShopPageWrapper/>} />
        <Route path='QrScanner' element={<QrCodePageWrapper/>} />
        <Route path='ResetPassword/*' element={<ResetPasswordPageWrapper/>} />
        {/* Lazy Modules */}
        <Route
          path='Programme/*'
          element={
            <SuspensedView>
              <ProgrammePage xpath={xpath} xpathname={xnamevar} />
            </SuspensedView>
          }
        />
        <Route
          path='pages/MyProfile/*'
          element={
            <SuspensedView>
              <ProfilePageVms />
            </SuspensedView>
          }
        />
        <Route
          path='pages/MyProfileAdmin/*'
          element={
            <SuspensedView>
              <ProfilePageVmsAdmin />
            </SuspensedView>
          }
        />
        <Route
          path='pages/MyProfileAdminDashboard/*'
          element={
            <SuspensedView>
              <ProfilePageVmsAdminDashboard />
            </SuspensedView>
          }
        />
        <Route
          path='pages/ContactInformationList/*'
          element={
            <SuspensedView>
              <ContactInfoList />
            </SuspensedView>
          }
        />
        <Route
          path='pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}
const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

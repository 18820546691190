/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {getLayout, ILayout, LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {ErrorMessage, Field} from 'formik'
import Swal from 'sweetalert2'
import $ from 'jquery';
import {useAuth} from '../../../app/modules/auth'
import LoadingAnimation from '../../LoadingAnimation'

type Props = {
  xdata: any
  xdata2:any[]
  loading2:boolean
}
const BuilderPagexTnc: React.FC<Props> = ({xdata,xdata2,loading2}) => {
  const {setLayout} = useLayout()
  const [tab, setTab] = useState('Header')
  const [config, setConfig] = useState<ILayout>(getLayout())
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const [resetLoading, setResetLoading] = useState<boolean>(false)
  const [agreement, setAgreement] = useState(false);
  const {currentUser} = useAuth()
  const Swal = require('sweetalert2')

  const handleChange = (event: { target: { checked: any } }) => {
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
      $("#btnTnc").removeAttr("disabled");
    } else {
      console.log('⛔️ Checkbox is NOT checked');
      $("#btnTnc").attr("disabled","disabled");
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You must accept terms and conditions first!',
      })
    }
    setAgreement(current => !current);
  };
  $('#btnTnc').bind('click', function() {
    //console.log("halo");
    if(currentUser){
      window.location.replace("/pages/wizards/BeAVolunteer");
    }else{
      Swal.fire({
        title: "Error!",
        html: 'You must login first, redirected to login page...',
        icon: "error",
        timer: 3000,
        showConfirmButton: false
      }).then(() => {
          window.location.replace("/auth/login");
      }) 
    }
  });
  const UnVerified = () => {
    return (
      <div className={`card mb-5 mb-xxl-8`}>
      {/* begin::Body */}
      <div className='card-body'>
      <div className="overflow-auto pb-5">
      <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed min-w-lg-600px flex-shrink-0 p-6">
      <span className="svg-icon svg-icon-primary svg-icon-2x" style={{marginRight:"10px",marginTop:"10px"}}>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24"/>
              <path d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M19.0710678,4.92893219 L19.0710678,4.92893219 C19.4615921,5.31945648 19.4615921,5.95262146 19.0710678,6.34314575 L6.34314575,19.0710678 C5.95262146,19.4615921 5.31945648,19.4615921 4.92893219,19.0710678 L4.92893219,19.0710678 C4.5384079,18.6805435 4.5384079,18.0473785 4.92893219,17.6568542 L17.6568542,4.92893219 C18.0473785,4.5384079 18.6805435,4.5384079 19.0710678,4.92893219 Z" fill="red" fill-rule="nonzero" opacity="0.3"/>
          </g>
        </svg>
      </span>
        {currentUser ?
        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
          <div className="mb-3 mb-md-0 fw-semibold">
            <h4 className="text-gray-900 fw-bold">Sorry, your account is not verified yet!</h4>
            <div className="fs-6 text-gray-700 pe-7">Please be patient for verification process, we will notify via email if your account is ready</div>
          </div>
        </div>
        :
          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
          <div className="mb-3 mb-md-0 fw-semibold">
            <h4 className="text-gray-900 fw-bold">Sorry, access to this page denied!</h4>
            <div className="fs-6 text-gray-700 pe-7">Please <a href="/auth/registration">register</a> first and wait for verification process, or if you're already registered please <a href="/auth/login">login</a> first.</div>
          </div>
        </div>       
        }
      </div>
    </div>   
    </div>
    </div>   
    )
  }
  return (
    <>
        <div className='card mb-10'>
        <div className='card-body d-flex align-items-center py-8'>
          {/* begin::Icon */}
          <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              className='text-primary h-75px w-75px h-lg-100px w-lg-100px position-absolute opacity-5'
            >
              <path
                fill='currentColor'
                d='M10.2,21.23,4.91,18.17a3.58,3.58,0,0,1-1.8-3.11V8.94a3.58,3.58,0,0,1,1.8-3.11L10.2,2.77a3.62,3.62,0,0,1,3.6,0l5.29,3.06a3.58,3.58,0,0,1,1.8,3.11v6.12a3.58,3.58,0,0,1-1.8,3.11L13.8,21.23A3.62,3.62,0,0,1,10.2,21.23Z'
              ></path>
            </svg>
            <KTIcon iconName='document' className='fs-2x fs-lg-3x text-primary position-absolute' />
          </div>
          {/* end::Icon */}

          {/* begin::Description */}
          <div className='ms-6'>
            <h2>Term and conditions</h2>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
              {loading2 && <LoadingAnimation/>}
              {xdata2.length > 0 && xdata2[0].ctContent}
            </p>
          </div>
          {/* end::Description */}
        </div>
      </div>
      {!currentUser?.usrIsVerified ? <UnVerified/> :
        <div className='card card-custom p-10'>
          <p>{<div dangerouslySetInnerHTML={{ __html: xdata.ctContent }}></div>}</p>
          <div className='d-flex mt-10'><input onChange={handleChange} type="checkbox" className='form-check-input' value="1" /><span style={{ marginLeft: "15px" }}>I agree to the terms and conditions</span></div>
          <div className='d-flex'><button id="btnTnc" className="btn btn-success btn-sm d-flex flex-center mt-15" disabled>Continue <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' /></button> </div>
        </div>
      }
    </>
  )
}

export {BuilderPagexTnc}

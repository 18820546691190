import React, { useEffect, useMemo, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {UpdateContent,SearchContent} from '../../modules/auth/core/_requests';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Form, Modal } from 'react-bootstrap';
import $ from 'jquery';
import {useAuth} from '../../../app/modules/auth'
type Props = {
	section4datax: any[],
	randomtestimoni: any[],
	hasContentEdit: boolean,
  }
const SectionFour: React.FC<Props> = ({section4datax,randomtestimoni,hasContentEdit})=>{
	const [xtitle1, setXtitle1] = useState("")
	const [xcontent1, setXcontent1] = useState("")
	const [xphoto1, setXphoto1] = useState("")
	const [xrate1, setXrate1] = useState("")
	const [xfullname1, setXfullname1] = useState("")
	const [xisadmin, setXisadmin] = useState(false)
	const [progname, setProgname] = useState("")
	const [createdbyrole, setCreatedbyrole] = useState(false)

	const [xtitle2, setXtitle2] = useState("")
	const [xcontent2, setXcontent2] = useState("")
	const [xphoto2, setXphoto2] = useState("")
	const [xrate2, setXrate2] = useState("")
	const [xfullname2, setXfullname2] = useState("")
	const [xisadmin2, setXisadmin2] = useState(false)
	const [progname2, setProgname2] = useState("")
	const [createdbyrole2, setCreatedbyrole2] = useState(false)

	const [xtitle3, setXtitle3] = useState("")
	const [xcontent3, setXcontent3] = useState("")
	const [xphoto3, setXphoto3] = useState("")
	const [xrate3, setXrate3] = useState("")
	const [xfullname3, setXfullname3] = useState("")
	const [xisadmin3, setXisadmin3] = useState(false)
	const [progname3, setProgname3] = useState("")
	const [createdbyrole3, setCreatedbyrole3] = useState(false)

	const [section4title, setSection4title] = useState("")
	const [section4titleid, setSection4titleid] = useState("")
	const [section4titlesec, setSection4titlesec] = useState("")

	const [section4subtitle, setSection4subtitle] = useState("")
	const [section4subtitleid, setSection4subtitleid] = useState("")
	const [section4subtitlesec, setSection4subtitlesec] = useState("")
	const [modalShow, setModalShow] = useState(false);
	const [contentId, setContentId] = useState(0);
	const [contentSection, setContentSection] = useState("");
	const [contentDesc, setContentDesc] = useState("");
	const Swal = require('sweetalert2')
	const {currentUser} = useAuth()
	const [section4data, setSection4data] = useState<any[]>(section4datax);

	const fetchContents = async () => {
		const {data: res} = await SearchContent("section4")
		setSection4data(res);
	  };

	const modules = useMemo(
		() => ({
		  toolbar: {
			container: [
			  // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
			  [{ header: [1, 2, 3, 4, 5, 6, false] }], 
			  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
			  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
			  ['link', 'image'], 
			  [{ color: [] }, { background: [] }, { align: [] }],
			  ['clean'],
			],
		  },
		}),
		[],
	  );
	  const formats = [
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'image',
		'code',
		'color',
		'background',
		'code-block',
		'align',
	  ];
	const AddContentModal = (props:any) => {
		const [quillvalue, setQuillvalue] = useState('');
		console.log("halo :" + props.section)
		const handleQuillChange = (value:any) => {
		  console.log(value);
		  setQuillvalue(value)
		};
		const handleUpdate = async () => {
		  try{
			$("button#btnUpdate").html("Please wait...")
			//alert($("#ctId").val());
			let ctIdx=Number($("#ctId").val()) as number;
			let ctSection=($("#ctSection").val()) as string;
			let ctContent=($("#ctContent").val()) as string;
			//alert(ctSection)
			const {data: res} = await UpdateContent(
			  ctIdx,
			  ctSection,
			  quillvalue,
			  currentUser?.usrId,
			)
			if(res.status == "success"){
			  Swal.fire({
				title: "Success!",
				text: "Content is updated succesfully",
				icon: "success",
				timer: 2000,
				showConfirmButton: false
			  })
			  fetchContents()
			}else{
			  Swal.fire({
				title: "Error!",
				text: "Content is failed to update",
				icon: "error",
				timer: 2000,
				showConfirmButton: false
			  })     
			}
			setModalShow(false)
			$("button#btnUpdate").html("Update")
		  } catch (error) {
			Swal.fire({
			  title: "Error!",
			  text: error,
			  icon: "error",
			  timer: 2000,
			  showConfirmButton: false
			})
			$("button#btnUpdate").html("Update")  
		  }
		}
		return (
		  <Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
		  >
			<Modal.Header closeButton>
			  <Modal.Title id="contained-modal-title-vcenter">
				Edit Content
			  </Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  <Form>
				  <input type="hidden" defaultValue={props.id} id="ctId"/>
				  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
					<Form.Label>Section</Form.Label>
					{props.section != "" ?
					  <Form.Control
						defaultValue={props.section}
						type="text"
						placeholder=""
						id="ctSection"
						autoFocus
						readOnly
					  />
					  :
					  <Form.Control
					  type="text"
					  placeholder=""
					  id="ctSection"
					  autoFocus
					/>
					}
				  </Form.Group>
				  <Form.Group
					className="mb-3"
					controlId="exampleForm.ControlTextarea1"
				  >
					<Form.Label>Content</Form.Label>
					<ReactQuill theme="snow" modules={modules} formats={formats} defaultValue={contentDesc} id="ctQuill" onChange={handleQuillChange}/>
				  </Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="primary" id="btnUpdate" onClick={handleUpdate}>Update</Button>
			  <Button variant="secondary" onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		  </Modal>
		);
	  }
	  useEffect(() => {
		for (let i = 0; i < section4data.length; i++) {
			let res = section4data[i].ctSection
			if(res == "Section4Title"){
				setSection4title(section4data[i].ctContent)
				setSection4titleid(section4data[i].ctId)
				setSection4titlesec(section4data[i].ctSection)
			}
			if(res == "Section4SubTitle"){
				setSection4subtitle(section4data[i].ctContent)
				setSection4subtitleid(section4data[i].ctId)
				setSection4subtitlesec(section4data[i].ctSection)
			}
		}
		if(randomtestimoni.length == 3){
			setXtitle1(randomtestimoni[0].title)
			setXcontent1(randomtestimoni[0].contentx)
			setXphoto1(randomtestimoni[0].photoBase64)
			setXrate1(randomtestimoni[0].rate)
			setXfullname1(randomtestimoni[0].fullName)
			setXisadmin(randomtestimoni[0].isAdmin)
			setProgname(randomtestimoni[0].programmeName)
			setCreatedbyrole(randomtestimoni[0].createdByRole)

			setXtitle2(randomtestimoni[1].title)
			setXcontent2(randomtestimoni[1].contentx)
			setXphoto2(randomtestimoni[1].photoBase64)
			setXrate2(randomtestimoni[1].rate)
			setXfullname2(randomtestimoni[1].fullName)
			setXisadmin2(randomtestimoni[1].isAdmin)
			setProgname2(randomtestimoni[1].programmeName)
			setCreatedbyrole2(randomtestimoni[1].createdByRole)

			setXtitle3(randomtestimoni[2].title)
			setXcontent3(randomtestimoni[2].contentx)
			setXphoto3(randomtestimoni[2].photoBase64)
			setXrate3(randomtestimoni[2].rate)
			setXfullname3(randomtestimoni[2].fullName)
			setXisadmin3(randomtestimoni[2].isAdmin)
			setProgname3(randomtestimoni[2].programmeName)
			setCreatedbyrole3(randomtestimoni[2].createdByRole)
		}
	  }, [section4data]);
	  const handleContentEdit = (idx:number, ctSection:string,ctDesc:string) => {
		setModalShow(true)
		setContentId(idx)
		setContentSection(ctSection)
		setContentDesc(ctDesc)
	  };
	  const rateList = () => {
		const rateItems = [];
		for (let i = 0; i < Number(xrate1); i++) {
			rateItems.push(<div  className="rating-label me-2 checked"><i  className="bi bi-star-fill fs-5"></i></div>);
		}
		return rateItems;
	  };
	  const rateList2 = () => {
		const rateItems2 = [];
		for (let i = 0; i < Number(xrate2); i++) {
			rateItems2.push(<div  className="rating-label me-2 checked"><i  className="bi bi-star-fill fs-5"></i></div>);
		}
		return rateItems2;
	  };
	  const rateList3 = () => {
		const rateItems3 = [];
		for (let i = 0; i < Number(xrate3); i++) {
			rateItems3.push(<div  className="rating-label me-2 checked"><i  className="bi bi-star-fill fs-5"></i></div>);
		}
		return rateItems3;
	  };
    return (
        <>
         <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <div className='col-xl-12'>
          <div  className="container">
					<div  className="text-center mb-17">
						<h3  className="fs-2hx text-dark mb-5" id="clients" data-kt-scroll-offset="{default: 125, lg: 150}">{<span dangerouslySetInnerHTML={{ __html: section4title }}></span>} {hasContentEdit && <a href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section4titleid),section4titlesec,section4title)}>[ Edit ]</a>}</h3>
						<div  className="fs-5 text-muted fw-bold">{<span dangerouslySetInnerHTML={{ __html: section4subtitle + "" }}></span>} {hasContentEdit && <a href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section4subtitleid),section4subtitlesec,section4subtitle)}>[ Edit ]</a>}</div>
					</div>
					<div  className="row g-lg-10 mb-10 mb-lg-20">
						{randomtestimoni.length < 3 ? 
						(<div  className="col-lg-12">
							<p className="text-center text-bold fs-2" style={{color:"red"}}>Testimonial data is not available to show in this widget!</p>
						</div>)
						:
						(<><div className="col-lg-4">
							<div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
								<div className="mb-7">
									<div className="rating mb-6">
										{rateList()}
									</div>
									<div className="fs-2 fw-bold text-dark mb-3">{xtitle1}</div>
									<div className="text-gray-500 fw-semibold fs-4">{<span dangerouslySetInnerHTML={{ __html: xcontent1 }}></span>}</div>
								</div>
								<div className="d-flex align-items-center">

									<div className="symbol symbol-circle symbol-50px me-5">
										{xphoto1 == "" ?
											<img title={xfullname1} src={toAbsoluteUrl('/media/images/noimage.jpg')} />
											:
											<img title={xfullname1} src={xphoto1} />}
									</div>
									<div className="flex-grow-1">
										<a href="#" className="text-dark fw-bold text-hover-primary fs-6">{xfullname1}</a>
										<span className="text-muted d-block fw-bold">{xisadmin ? "Administrator" : "Volunteer"} - {progname}</span>
									</div>
								</div>
							</div>
						</div><div className="col-lg-4">
								<div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
									<div className="mb-7">
										<div className="rating mb-6">
											{rateList2()}
										</div>
										<div className="fs-2 fw-bold text-dark mb-3">{xtitle2}</div>
										<div className="text-gray-500 fw-semibold fs-4">{<span dangerouslySetInnerHTML={{ __html: xcontent2 }}></span>}</div>
									</div>
									<div className="d-flex align-items-center">
										<div className="symbol symbol-circle symbol-50px me-5">
											{xphoto2 == "" ?
												<img title={xfullname2} src={toAbsoluteUrl('/media/images/noimage.jpg')} />
												:
												<img title={xfullname2} src={xphoto2} />}
										</div>
										<div className="flex-grow-1">
											<a href="#" className="text-dark fw-bold text-hover-primary fs-6">{xfullname2}</a>
											<span className="text-muted d-block fw-bold">{xisadmin2 ? "Administrator" : "Volunteer"} - {progname2}</span>
										</div>
									</div>
								</div>
							</div><div className="col-lg-4">
								<div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
									<div className="mb-7">
										<div className="rating mb-6">
											{rateList3()}
										</div>
										<div className="fs-2 fw-bold text-dark mb-3">{xtitle3}</div>
										<div className="text-gray-500 fw-semibold fs-4">{<span dangerouslySetInnerHTML={{ __html: xcontent3 }}></span>}</div>
									</div>
									<div className="d-flex align-items-center">
										<div className="symbol symbol-circle symbol-50px me-5">
											{xphoto3 == "" ?
												<img title={xfullname3} src={toAbsoluteUrl('/media/images/noimage.jpg')} />
												:
												<img title={xfullname3} src={xphoto3} />}
										</div>
										<div className="flex-grow-1">
											<a href="#" className="text-dark fw-bold text-hover-primary fs-6">{xfullname3}</a>
											<span className="text-muted d-block fw-bold">{xisadmin3 ? "Administrator" : "Volunteer"} - {progname3}</span>
										</div>
									</div>
								</div>
							</div></>)
						}
					</div>
				</div>
          </div>
        </div>       
		<AddContentModal
        id={contentId}
        section={contentSection}
        desc={contentDesc}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />  
        </>
    )
}
export default SectionFour;
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'

const HeaderAdminMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const handleSignout=()=>{
    logout()
    document.location.replace("/dashboard")
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            { currentUser?.usrPhotoBase64 == "" ?
            <img title={currentUser?.usrFirstname + " " + currentUser?.usrLastname} src={toAbsoluteUrl('/media/images/noimage.jpg')} />
            :
            <img title={currentUser?.usrFirstname + " " + currentUser?.usrLastname} src={currentUser?.usrPhotoBase64} />
            }
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5'>
            {currentUser?.usrFirstname} {currentUser?.usrLastname}
              <span className='badge badge-light-danger fw-bold fs-8 px-2 py-1 ms-2'>{currentUser?.usrRoleName}</span>
            </div>
            <a href='#' className='fw-semibold text-muted text-hover-primary fs-7'>
              {currentUser?.usrEmail}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to={toAbsoluteUrl('/pages/MyProfileAdmin/Overview')} className='menu-link px-5'>
        <i className="ki-duotone ki-user fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;Admin Dashboard
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to={toAbsoluteUrl('/pages/MyProfileAdminDashboard/SetupProgrammes')} className='menu-link px-5'>
        <i className="ki-duotone ki-abstract-14 fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;Manage Data
        </Link>
      </div>
      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'><i className="ki-duotone ki-information-2 fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;Information</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
          <Link to={toAbsoluteUrl('/pages/ContactInformationList/VolunteerContact')}  className='menu-link px-5'>
        Volunteer Contact
        </Link>
          </div>

          <div className='menu-item px-3'>
          <Link to={toAbsoluteUrl('/pages/ContactInformationList/MembershipBeneficiary')} className='menu-link px-5'>
        Participant Beneficiary Contact
        </Link>
          </div>
          <div className='menu-item px-3'>
          <Link to={toAbsoluteUrl('/pages/ContactInformationList/TuitionBeneficiary')} className='menu-link px-5'>
        Tuition Beneficiary Contact
        </Link>
          </div>
        </div>
      </div>
{/*       <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'><i className="ki-duotone ki-people fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;Attendance</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
          <Link to={'#'} className='menu-link px-5'>
          Beneficiaries
        </Link>
          </div>

          <div className='menu-item px-3'>
          <Link to={'#'} className='menu-link px-5'>
          Volunteers
        </Link>
          </div>
        </div>
      </div> */}
{/*       <div className='menu-item px-5'>
        <a href={toAbsoluteUrl('/pages/MyProfileAdminDashboard/SetupProgrammes')} className='menu-link px-5'>
        <i className="ki-duotone ki-setting-2 fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;Programmes
        </a>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5 my-1'>
        <Link to={toAbsoluteUrl('/pages/MyProfileAdminDashboard/ListOfEvents')} className='menu-link px-5'>
        <i className="ki-duotone ki-messages fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;Mass SMS/Email
        </Link>
      </div>
      <div className='menu-item px-5 my-1'>
        <Link to={toAbsoluteUrl('/pages/MyProfileAdminDashboard/Reports')} className='menu-link px-5'>
        <i className="ki-duotone ki-tablet-text-down fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;Reports
        </Link>
      </div>
      <div className='menu-item px-5 my-1'>
        <Link to={toAbsoluteUrl('/pages/MyProfileAdminDashboard/SystemSettings')} className='menu-link px-5'>
        <i className="ki-duotone ki-gear fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;System Settings
        </Link>
      </div> */}
{/*       <div className='menu-item px-5 my-1'>
        <Link to={toAbsoluteUrl('/pages/MyProfileAdminDashboard/MergeDatabaseProfile')} className='menu-link px-5'>
        <i className="ki-duotone ki-cloud fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;User Profiles
        </Link>
      </div> */}
{/*       <div className='menu-item px-5 my-1'>
        <Link to={toAbsoluteUrl('/pages/MyProfileAdminDashboard/UserManager')} className='menu-link px-5'>
        <i className="ki-duotone ki-profile-user fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;User Manager
        </Link>
      </div>
      <div className='menu-item px-5 my-1'>
        <Link to={toAbsoluteUrl('/pages/MyProfileAdminDashboard/PartnerInformation')} className='menu-link px-5'>
        <i className="ki-duotone ki-information-4 fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;Partners
        </Link>
      </div>
      <div className='menu-item px-5 my-1'>
        <Link to={toAbsoluteUrl('/pages/MyProfileAdminDashboard/Contents')} className='menu-link px-5'>
        <i className="ki-duotone ki-pencil fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;Contents
        </Link>
      </div> */}
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <a onClick={() => { handleSignout() }} className='menu-link px-5'>
        <i className="ki-duotone ki-exit-left fs-2"><i className="path1"></i><i className="path2"></i></i>&nbsp;&nbsp;Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderAdminMenu}

import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {CheckResetPasswordToken} from '../../modules/auth/core/_requests'
import { useEffect, useRef, useState } from "react";

// Qr Scanner
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import axios from 'axios';
import { ResetPassword } from './ResetPassword';
import { useLocation } from 'react-router-dom';
import LoadingAnimation from '../../LoadingAnimation';

const aboutBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/Reset Password',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const NoCompleted = () => {
  return (
    <div className={`card mb-2 mb-xxl-8`}>
    {/* begin::Body */}
    <div className='card-body'>
    <div className="overflow-auto pb-5">
    <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed min-w-lg-600px flex-shrink-0 p-6">
    <span className="svg-icon svg-icon-primary svg-icon-2x" style={{marginRight:"10px",marginTop:"10px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect x="0" y="0" width="24" height="24"/>
            <path d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M19.0710678,4.92893219 L19.0710678,4.92893219 C19.4615921,5.31945648 19.4615921,5.95262146 19.0710678,6.34314575 L6.34314575,19.0710678 C5.95262146,19.4615921 5.31945648,19.4615921 4.92893219,19.0710678 L4.92893219,19.0710678 C4.5384079,18.6805435 4.5384079,18.0473785 4.92893219,17.6568542 L17.6568542,4.92893219 C18.0473785,4.5384079 18.6805435,4.5384079 19.0710678,4.92893219 Z" fill="red" fill-rule="nonzero" opacity="0.3"/>
        </g>
      </svg>
    </span>
      <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
        <div className="mb-3 mb-md-0 fw-semibold">
          <h4 className="text-gray-900 fw-bold">Error!</h4>
          <div className="fs-6 text-gray-700 pe-7">Your token is invalid or has been expired.</div>
        </div>
      </div>
    </div>
  </div>   
  </div>
  </div>   
  )
}
const ResetPasswordPageWrapper: FC = () => {
  const location = useLocation()
  let xpath = location.pathname;
  let token=xpath.split("/")[xpath.split("/").length -1].toLowerCase();
  //const [scannedResultProgramme, setScannedResultProgramme] = useState<string | undefined>("");
  const Swal = require('sweetalert2')
  const [loading, setLoading] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const fetchCheckToken = async () => {
    setLoading(true);

    const {data: xcheck } = await CheckResetPasswordToken(token);

    console.log(xcheck);
    setIsOk(xcheck.status == "success" ? true : false);
    setLoading(false);
  };
  useEffect(() => {
    fetchCheckToken()
  }, []);
  return (
    <>
      <PageTitle breadcrumbs={aboutBreadCrumbs}>Forgot Password</PageTitle>
      <div className='card mb-10'>
        <div className='card-body d-flex align-items-center py-8'>
          {/* begin::Icon */}
          <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              className='text-primary h-75px w-75px h-lg-100px w-lg-100px position-absolute opacity-5'
            >
              <path
                fill='currentColor'
                d='M10.2,21.23,4.91,18.17a3.58,3.58,0,0,1-1.8-3.11V8.94a3.58,3.58,0,0,1,1.8-3.11L10.2,2.77a3.62,3.62,0,0,1,3.6,0l5.29,3.06a3.58,3.58,0,0,1,1.8,3.11v6.12a3.58,3.58,0,0,1-1.8,3.11L13.8,21.23A3.62,3.62,0,0,1,10.2,21.23Z'
              ></path>
            </svg>
            <KTIcon iconName='shop' className='fs-2x fs-lg-3x text-primary position-absolute' />
          </div>
          {/* end::Icon */}

          {/* begin::Description */}
          <div className='ms-6'>
            <h2>Reset Your Password</h2>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
              Please create your new password in this page
            </p>
          </div>
          {/* end::Description */}
        </div>
      </div>
      <div className='card card-custom'>
        <div className='row'>
          <div className='col-md-12'>
            {loading && <LoadingAnimation/>}
            {isOk ?
            <ResetPassword token={token}/>
            :
            <NoCompleted/>
            }
          </div> 
        </div>
      </div>
    </>
  )
}

export default ResetPasswordPageWrapper


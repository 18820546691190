import React,{useState,useEffect, useMemo} from "react";
import Carousel from 'react-bootstrap/Carousel';
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import LoadingAnimation from "../../LoadingAnimation";
import {useAuth} from '../../../app/modules/auth'
import {UpdateContent,SearchContent} from '../../modules/auth/core/_requests';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Form, Modal } from 'react-bootstrap';
import $ from 'jquery';

type Props = {
	section2datax: any[],
	hasContentEdit: boolean,
  }
const SectionTwo: React.FC<Props> = ({section2datax,hasContentEdit})=>{
	const [section2title, setSection2title] = useState("")
	const [section2titleid, setSection2titleid] = useState("")
	const [section2titlesec, setSection2titlesec] = useState("")

	const [section2subtitle, setSection2subtitle] = useState("")
	const [section2subtitleid, setSection2subtitleid] = useState("")
	const [section2subtitlesec, setSection2subtitlesec] = useState("")

	const [section2step1title, setSection2Step1Title] = useState("")
	const [section2step1titleid, setSection2Step1Titleid] = useState("")
	const [section2step1titlesec, setSection2Step1Titlesec] = useState("")

	const [section2step1subtitle, setSection2Step1SubTitle] = useState("")
	const [section2step1subtitleid, setSection2Step1SubTitleid] = useState("")
	const [section2step1subtitlesec, setSection2Step1SubTitlesec] = useState("")

	const [section2step2title, setSection2Step2Title] = useState("")
	const [section2step2titleid, setSection2Step2Titleid] = useState("")
	const [section2step2titlesec, setSection2Step2Titlesec] = useState("")

	const [section2step2subtitle, setSection2Step2SubTitle] = useState("")
	const [section2step2subtitleid, setSection2Step2SubTitleid] = useState("")
	const [section2step2subtitlesec, setSection2Step2SubTitlesec] = useState("")

	const [section2step3title, setSection2Step3Title] = useState("")
	const [section2step3titleid, setSection2Step3Titleid] = useState("")
	const [section2step3titlesec, setSection2Step3Titlesec] = useState("")

	const [section2step3subtitle, setSection2Step3SubTitle] = useState("")
	const [section2step3subtitleid, setSection2Step3SubTitleid] = useState("")
	const [section2step3subtitlesec, setSection2Step3SubTitlesec] = useState("")

	const [modalShow, setModalShow] = useState(false);
	const [contentId, setContentId] = useState(0);
	const [contentSection, setContentSection] = useState("");
	const [contentDesc, setContentDesc] = useState("");
	const Swal = require('sweetalert2')
	const {currentUser} = useAuth()
	const [loading, setLoading] = useState(false);
	const [section2data, setSection2data] = useState<any[]>(section2datax);

	const fetchContents = async () => {
		setLoading(true);
		const {data: res} = await SearchContent("section2")
		setSection2data(res);
		setLoading(false);
	  };

	const modules = useMemo(
		() => ({
		  toolbar: {
			container: [
			  // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
			  [{ header: [1, 2, 3, 4, 5, 6, false] }], 
			  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
			  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
			  ['link', 'image'], 
			  [{ color: [] }, { background: [] }, { align: [] }],
			  ['clean'],
			],
		  },
		}),
		[],
	  );
	  const formats = [
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'image',
		'code',
		'color',
		'background',
		'code-block',
		'align',
	  ];
	const AddContentModal = (props:any) => {
		const [quillvalue, setQuillvalue] = useState('');
		console.log("halo :" + props.section)
		const handleQuillChange = (value:any) => {
		  console.log(value);
		  setQuillvalue(value)
		};
		const handleUpdate = async () => {
		  try{
			$("button#btnUpdate").html("Please wait...")
			//alert($("#ctId").val());
			let ctIdx=Number($("#ctId").val()) as number;
			let ctSection=($("#ctSection").val()) as string;
			let ctContent=($("#ctContent").val()) as string;
			//alert(ctSection)
			const {data: res} = await UpdateContent(
			  ctIdx,
			  ctSection,
			  quillvalue,
			  currentUser?.usrId,
			)
			if(res.status == "success"){
			  Swal.fire({
				title: "Success!",
				text: "Content is updated succesfully",
				icon: "success",
				timer: 2000,
				showConfirmButton: false
			  })
			  fetchContents()
			}else{
			  Swal.fire({
				title: "Error!",
				text: "Content is failed to update",
				icon: "error",
				timer: 2000,
				showConfirmButton: false
			  })     
			}
			setModalShow(false)
			$("button#btnUpdate").html("Update")
		  } catch (error) {
			Swal.fire({
			  title: "Error!",
			  text: error,
			  icon: "error",
			  timer: 2000,
			  showConfirmButton: false
			})
			$("button#btnUpdate").html("Update")  
		  }
		}
		return (
		  <Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
		  >
			<Modal.Header closeButton>
			  <Modal.Title id="contained-modal-title-vcenter">
				Edit Content
			  </Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  <Form>
				  <input type="hidden" defaultValue={props.id} id="ctId"/>
				  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
					<Form.Label>Section</Form.Label>
					{props.section != "" ?
					  <Form.Control
						defaultValue={props.section}
						type="text"
						placeholder=""
						id="ctSection"
						autoFocus
						readOnly
					  />
					  :
					  <Form.Control
					  type="text"
					  placeholder=""
					  id="ctSection"
					  autoFocus
					/>
					}
				  </Form.Group>
				  <Form.Group
					className="mb-3"
					controlId="exampleForm.ControlTextarea1"
				  >
					<Form.Label>Content</Form.Label>
					<ReactQuill theme="snow" modules={modules} formats={formats} defaultValue={contentDesc} id="ctQuill" onChange={handleQuillChange}/>
				  </Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="primary" id="btnUpdate" onClick={handleUpdate}>Update</Button>
			  <Button variant="secondary" onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		  </Modal>
		);
	  }
	useEffect(() => {
		console.log(section2data);
		for (let i = 0; i < section2data.length; i++) {
			let res = section2data[i].ctSection
			if(res == "Section2Title"){
				setSection2title(section2data[i].ctContent)
				setSection2titleid(section2data[i].ctId)
				setSection2titlesec(section2data[i].ctSection)
			}
			if(res == "Section2SubTitle"){
				setSection2subtitle(section2data[i].ctContent)
				setSection2subtitleid(section2data[i].ctId)
				setSection2subtitlesec(section2data[i].ctSection)
			}
			if(res == "Section2Step1Title"){
				setSection2Step1Title(section2data[i].ctContent)
				setSection2Step1Titleid(section2data[i].ctId)
				setSection2Step1Titlesec(section2data[i].ctSection)
			}
			if(res == "Section2Step1SubTitle"){
				setSection2Step1SubTitle(section2data[i].ctContent)
				setSection2Step1SubTitleid(section2data[i].ctId)
				setSection2Step1SubTitlesec(section2data[i].ctSection)
			}
			if(res == "Section2Step2Title"){
				setSection2Step2Title(section2data[i].ctContent)
				setSection2Step2Titleid(section2data[i].ctId)
				setSection2Step2Titlesec(section2data[i].ctSection)
			}
			if(res == "Section2Step2SubTitle"){
				setSection2Step2SubTitle(section2data[i].ctContent)
				setSection2Step2SubTitleid(section2data[i].ctId)
				setSection2Step2SubTitlesec(section2data[i].ctSection)
			}
			if(res == "Section2Step3Title"){
				setSection2Step3Title(section2data[i].ctContent)
				setSection2Step3Titleid(section2data[i].ctId)
				setSection2Step3Titlesec(section2data[i].ctSection)
			}
			if(res == "Section2Step3SubTitle"){
				setSection2Step3SubTitle(section2data[i].ctContent)
				setSection2Step3SubTitleid(section2data[i].ctId)
				setSection2Step3SubTitlesec(section2data[i].ctSection)
			}
		}
	  }, [section2data]);
	  const handleContentEdit = (idx:number, ctSection:string,ctDesc:string) => {
		setModalShow(true)
		setContentId(idx)
		setContentSection(ctSection)
		setContentDesc(ctDesc)
	  };
	console.log(section2data);
    return (
        <>
         <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <div className='col-xl-12'>
          <div className="text-center mb-17">
						<h3 className="fs-2hx text-dark mb-5" id="how-it-works" data-kt-scroll-offset="{default: 100, lg: 150}">{<span dangerouslySetInnerHTML={{ __html: section2title }}></span>} {hasContentEdit && <a href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section2titleid),section2titlesec,section2title)}>[ Edit ]</a>}</h3>
						<div className="fs-5 text-muted fw-bold">{<span dangerouslySetInnerHTML={{ __html: section2subtitle + "" }}></span>} {hasContentEdit && <a href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section2subtitleid),section2subtitlesec,section2subtitle)}>[ Edit ]</a>}</div>
					</div>
          <div className="row w-100 gy-10 mb-md-20">
						<div className="col-md-4 px-5">
							<div className="text-center mb-10 mb-md-0">
								<img src={toAbsoluteUrl('/media/illustrations/sketchy-1/2.png')} className="mh-125px mb-9" alt=""/>
								<div className="d-flex flex-center mb-5">
									<span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">1</span>
									<div className="fs-5 fs-lg-3 fw-bold text-dark mt-2">{<span dangerouslySetInnerHTML={{ __html: section2step1title }}></span>} {hasContentEdit && <a href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section2step1titleid),section2step1titlesec,section2step1title)}>[ Edit ]</a>}</div>
								</div>
								<div className="fw-semibold fs-6 fs-lg-4 text-muted">{<span dangerouslySetInnerHTML={{ __html: section2step1subtitle }}></span>} {hasContentEdit && <a href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section2step1subtitleid),section2step1subtitlesec,section2step1subtitle)}>[ Edit ]</a>}</div>
							</div>
						</div>
						<div className="col-md-4 px-5">
							<div className="text-center mb-10 mb-md-0">
								<img src={toAbsoluteUrl('/media/illustrations/sketchy-1/8.png')} className="mh-125px mb-9" alt=""/>
								<div className="d-flex flex-center mb-5">
									<span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">2</span>
									<div className="fs-5 fs-lg-3 fw-bold text-dark mt-2">{<span dangerouslySetInnerHTML={{ __html: section2step2title }}></span>} {hasContentEdit && <a href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section2step2titleid),section2step2titlesec,section2step2title)}>[ Edit ]</a>}</div>
								</div>
								<div className="fw-semibold fs-6 fs-lg-4 text-muted">{<span dangerouslySetInnerHTML={{ __html: section2step2subtitle }}></span>} {hasContentEdit && <a href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section2step2subtitleid),section2step2subtitlesec,section2step2subtitle)}>[ Edit ]</a>}</div>
							</div>
						</div>
						<div className="col-md-4 px-5">
							<div className="text-center mb-10 mb-md-0">
								<img src={toAbsoluteUrl('/media/illustrations/sketchy-1/12.png')} className="mh-125px mb-9" alt=""/>
								<div className="d-flex flex-center mb-5">
									<span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">3</span>
									<div className="fs-5 fs-lg-3 fw-bold text-dark mt-2">{<span dangerouslySetInnerHTML={{ __html: section2step3title }}></span>} {hasContentEdit && <a href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section2step3titleid),section2step3titlesec,section2step3title)}>[ Edit ]</a>}</div>
								</div>
								<div className="fw-semibold fs-6 fs-lg-4 text-muted">{<span dangerouslySetInnerHTML={{ __html: section2step3subtitle }}></span>} {hasContentEdit && <a href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section2step3subtitleid),section2step3subtitlesec,section2step3subtitle)}>[ Edit ]</a>}</div>
							</div>
						</div>
					</div>
          </div>
        </div>   
		<AddContentModal
        id={contentId}
        section={contentSection}
        desc={contentDesc}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />    
        </>
    )
}
export default SectionTwo;
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import { toAbsoluteUrl} from '../../../helpers'
import { HeaderUserMenu,  LoginButton} from '../../../partials'
import {useAuth} from '../../../../app/modules/auth'
import { HeaderAdminMenu } from '../../../partials/layout/header-menus/HeaderAdminMenu'
import {Search} from '../../../partials'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'fs-1'

const Topbar: FC = () => {
  const {currentUser, logout} = useAuth()
  const LoginBtn=()=>{
    return (
      <>
         <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
          <LoginButton/>
        </div>     
      </>
    )
  }
  const UserBtn=()=>{
    return (
      <>
        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            {currentUser?.usrPhotoBase64 == "" ?
                <img
                  className='rounded'
                  src={toAbsoluteUrl('/media/images/noimage.jpg')}
                  title={currentUser?.usrFirstname + " " + currentUser?.usrLastname}
                />     
              :
                <img
                className='rounded'
                src={currentUser?.usrPhotoBase64}
                title={currentUser?.usrFirstname + " " + currentUser?.usrLastname}
              />                
            }
          </div>
          {currentUser?.usrIsAdmin ? <HeaderAdminMenu/> :<HeaderUserMenu />}
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </>
    )
  }
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
{/*       <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
          <Search />
        </div> */}
      {currentUser ? <UserBtn/> : <LoginBtn/>}
      </div>
    </div>
  )
}

export {Topbar}
